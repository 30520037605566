import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { AudienceGrowth } from '../store/Slices/AudienceSlice';

export default function Audiencegrowth({ FBData, Fbunfollow }) {
    const [FbGrowth, setFbGrowth] = useState([]);
    const [IsLoader, setIsloader] = useState(true);
    const dispatch=useDispatch();

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                }
            }
        },
        elements: {
            point: {
                radius: 0
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 30
                }
            }
        }

    };

    const data = {
        datasets: [
            {
                label: 'Facebook', // Name the series
                data: FbGrowth, // Specify the data values array
                fill: true,
                borderColor: '#6F5ED3', // Add custom color border (Line)
                backgroundColor: '#6F5ED3', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.2
            },
            //     {
            //         label: 'Instagram', // Name the series
            //         data: InstaGrowth, // Specify the data values array
            //         fill: true,
            //         borderColor: '#CE3665', // Add custom color border (Line)
            //         backgroundColor: '#CE3665', // Add custom color background (Points and Fill)
            //         borderWidth: 1, // Specify bar border width
            //         tension: 0.2
            //     },
            //     {
            //         label: 'LinkedIn', // Name the series
            //         data: LinkedinGrowth, // Specify the data values array
            //         fill: true,
            //         borderColor: '#FFCD1C', // Add custom color border (Line)
            //         backgroundColor: '#FFCD1C', // Add custom color background (Points and Fill)
            //         borderWidth: 1, // Specify bar border width
            //         tension: 0.3
            //     },
            //     {
            //         label: 'Twitter', // Name the series
            //         data: TwitterGrowth, // Specify the data values array
            //         fill: true,
            //         borderColor: '#08C4B2', // Add custom color border (Line)
            //         backgroundColor: '#08C4B2', // Add custom color background (Points and Fill)
            //         borderWidth: 1, // Specify bar border width
            //         tension: 0.3
            //     }
            // ],
        ]
    };

    const FbGrowths = () => {
        const NewFbData = [];

        // Sum same date Values
        const FbFollow = Object.values(FBData.reduce((a, { end_time, value }) => {
            a[end_time] = (a[end_time] || { end_time, value: 0 });
            a[end_time].value = parseInt(String(Number(a[end_time].value) + Number(value)));
            return a;
        }, {}));

        const FBunfollow = Object.values(Fbunfollow.reduce((a, { end_time, value }) => {
            a[end_time] = (a[end_time] || { end_time, value: 0 });
            a[end_time].value = -String(Number(a[end_time].value) + Number(value));
            return a;
        }, {}));

        NewFbData.push(...FbFollow, ...FBunfollow)

        // Sort by date
        NewFbData.sort(function (a, b) {
            // Convert string dates into `Date` objects
            const date1 = new Date(a.end_time);
            const date2 = new Date(b.end_time);

            return date1 - date2;
        })

        // Push Data to new state
        const newData = NewFbData.map((elem) => {
            FbGrowth.push({
                x: (elem.end_time).split("T")[0].split("-")[1] + "/" + (elem.end_time).split("T")[0].split("-")[2],
                y: elem.value
            })
        });

        setFbGrowth(newData);

        const totalAudeience=data.datasets[0].data.reduce((accumulator, currentValue) =>
            accumulator + currentValue.y, 0
        )

        dispatch(AudienceGrowth(totalAudeience))

        
        setIsloader(false)
    }



    useEffect(() => {
        FbGrowths()
    }, [FBData, Fbunfollow])
    return (
        <div style={{ height: 'auto', paddingLeft: '30px', paddingRight: '30px', paddingTop: '40px' }}>
            {/* {
                IsLoader == true
                    ?
                    <div className='d-flex align-items-center justify-content-center'>
                        <Spin />
                    </div>
                    :
                    <div>
                        <Line options={options} data={data} />
                    </div>
            } */}

            {
                IsLoader
                    ?
                    <div className='d-flex align-items-center justify-content-center'>
                        <Spin />
                    </div>
                    :
                    <div>
                        <Line options={options} data={data} />
                    </div>
            }

            <div className='w-100 px-3'>
                <table className='w-100 table chart_table'>
                    <tr>
                        <th>
                            Audience Metrics
                        </th>

                        <th>
                            Totals
                        </th>

                        <th>
                            % Change
                        </th>
                    </tr>

                    <tbody>
                        <tr>
                            <td>
                                Total Audience
                            </td>

                            <td>
                                {
                                    FbGrowth != null
                                        ?
                                        data.datasets[0].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        )
                                        :
                                        0
                                }
                            </td>

                            <td>
                                2.1%
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Total Net Audience Growth
                            </td>

                            <td>

                            </td>

                            <td>
                                130%
                            </td>
                        </tr>

                        {/* <tr>
                            <td>
                                Instagram Net Follower Growth
                            </td>
                            <td>
                                {
                                    data.datasets[1].data.reduce((accumulator, currentValue) =>
                                        accumulator + currentValue.y, 0
                                    )
                                }
                            </td>

                            <td>
                                2400%
                            </td>
                        </tr> */}

                        <tr>
                            <td>
                                Facebook Net Page Likes
                            </td>
                            <td>
                                {
                                    FbGrowth != null
                                        ?
                                        data.datasets[0].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        )
                                        :
                                        null
                                }
                            </td>

                            <td>
                                2400%
                            </td>
                        </tr>

                        {/* <tr>
                            <td>
                                LinkedIn Net Page Likes
                            </td>
                            <td>
                                {
                                    isLoader
                                        ?
                                        data.datasets[2].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        )
                                        :
                                        0
                                }
                            </td>

                            <td>
                                2400%
                            </td>
                        </tr> */}

                        {/* <tr>
                            <td>
                                Twitter Net Page Likes
                            </td>
                            <td>
                                {
                                    isLoader
                                        ?
                                        data.datasets[3].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        )
                                        :
                                        0
                                }
                            </td>

                            <td>
                                2400%
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>


        </div>
    )
}
