import { createSlice } from "@reduxjs/toolkit";

const AudienceSlice=createSlice({
    name:"AudienceSlice",
    initialState:[],
    reducers:{
        AudienceGrowth(state,action){  
            state.length=0          
            state.push(action.payload)
            // state=action.payload
            // return
        },
    }
})

export {AudienceSlice}

export const {AudienceGrowth}=AudienceSlice.actions