import { configureStore } from "@reduxjs/toolkit";
import { DateSlice } from "./Slices/DateSlice";
import { UntilDateSlice } from "./Slices/UntilDateSlice";
import { SideSlice } from "./Slices/SIdeSlice";
import { ProfileSlice } from "./Slices/ProfileSlice";
import {FilterSlice} from './Slices/FilterSlice'
import { AudienceSlice } from "./Slices/AudienceSlice";
import { ImpressionSlice } from "./Slices/ImpressionSlice";
import { EngagmentSlice } from "./Slices/EngagementSlice";
import { CountrySlice } from "./Slices/CountrySlice";
import { TimezoneSlice } from "./Slices/TimezoneSlice";

const store=configureStore({
    reducer:{
        SinceDates:DateSlice.reducer,
        UntilDates:UntilDateSlice.reducer,
        SideBarName:SideSlice.reducer,
        Profiles:ProfileSlice.reducer,
        Filter:FilterSlice.reducer,
        AudienceGrow: AudienceSlice.reducer,
        Impression: ImpressionSlice.reducer,
        Engagement: EngagmentSlice.reducer,
        Country: CountrySlice.reducer,
        Timezone:TimezoneSlice.reducer
    }
})

export default store
