import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CountryDropdown from '../Comman Components/CountryDropdown';
import { country } from '../Comman Components/Country';
import TimezoneDropdown from '../Comman Components/TimezoneDropdown';
import { TimeZone } from '../Comman Components/TimeZone';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Countrychange } from './store/Slices/CountrySlice';
import { TimezoneChange } from './store/Slices/TimezoneSlice';

export default function ProfileBusiness() {

    const dispatch = useDispatch();

    const [ProfileData, setProfileData] = useState(null);
    const [FactsEditable, setFactsEditable] = useState(false);
    const [WritingEditable, setWitingEditable] = useState(false);
    const [CustomerEditable, setCustomerEditable] = useState(false);
    const [captionlength, setCaptionLength] = useState('Mid');
    const [EmojiLevel, setEmojiLevel] = useState('Mid');
    const [PromotionalLevel, setPromotionalLevel] = useState('Mid');


    const [isLoader, setLoader] = useState(false);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;


    const handleFieldChange = (field, value, index = null) => {
        setProfileData((prevData) => {
            if (index !== null) {
                // If editing an item in an array (like CTAs), update only that item
                const updatedArray = prevData[field].map((item, i) => (i === index ? value : item));
                return { ...prevData, [field]: updatedArray };
            } else {
                // If editing a single value field (like brand_name)
                return { ...prevData, [field]: value };
            }
        });
    }

    const GetProfileDetails = async () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }
        await axios.get(`${process.env.REACT_APP_BACKEND_API}/onboarding/get-onboarding`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setProfileData(res.data.OnBoarding[0]);
                setCaptionLength(res.data.OnBoarding[0].caption_sentence_length);
                setEmojiLevel(res.data.OnBoarding[0].emoji_level);
                setPromotionalLevel(res.data.OnBoarding[0].self_promotional_level);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleDelete = (index, editType) => {
        if (editType == "CAT") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.CTAs.filter((_, i) => i !== index);
                return { ...prevData, CTAs: updatedCTAs };
            });
        } else if (editType == "niche") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.my_niche.filter((_, i) => i !== index);
                return { ...prevData, my_niche: updatedCTAs };
            });
        } else if (editType == "core") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.core_values.filter((_, i) => i !== index);
                return { ...prevData, core_values: updatedCTAs };
            });
        } else if (editType == "audience") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.audience.filter((_, i) => i !== index);
                return { ...prevData, audience: updatedCTAs };
            });
        } else if (editType == "objectives") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.objectives.filter((_, i) => i !== index);
                return { ...prevData, objectives: updatedCTAs };
            });
        } else if (editType == "painpoints") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.pain_points.filter((_, i) => i !== index);
                return { ...prevData, pain_points: updatedCTAs };
            });
        }
    };

    const handleEdit = (index, newValue, editType) => {
        if (editType == "CTA") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.CTAs.map((cta, i) => (i === index ? newValue : cta));
                return { ...prevData, CTAs: updatedCTAs };
            });
        }
        else if (editType == "niche") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.my_niche.map((cta, i) => (i === index ? newValue : cta));
                return { ...prevData, my_niche: updatedCTAs };
            });
        } else if (editType == "core") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.core_values.map((cta, i) => (i === index ? newValue : cta));
                return { ...prevData, core_values: updatedCTAs };
            });
        } else if (editType == "audience") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.audience.map((cta, i) => (i === index ? newValue : cta));
                return { ...prevData, audience: updatedCTAs };
            });
        } else if (editType == "objectives") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.objectives.map((cta, i) => (i === index ? newValue : cta));
                return { ...prevData, objectives: updatedCTAs };
            });
        } else if (editType == "painpoints") {
            setProfileData((prevData) => {
                const updatedCTAs = prevData.pain_points.map((cta, i) => (i === index ? newValue : cta));
                return { ...prevData, pain_points: updatedCTAs };
            });
        }
    };



    const UpdateOnboardingData = () => {
        setLoader(true)
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        delete ProfileData.website;

        ProfileData.caption_sentence_length = captionlength;
        ProfileData.emoji_level = EmojiLevel;
        ProfileData.self_promotional_level = PromotionalLevel;

        axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/add-onboarding2`, ProfileData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setFactsEditable(false);
                setWitingEditable(false);
                setCustomerEditable(false)
                setLoader(false);
                GetProfileDetails()
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }

    useEffect(() => {
        GetProfileDetails()
    }, [])

    return (
        <div>
            <div className='section_header'>
                <h4>
                    Business Profile
                </h4>
            </div>

            {/* Facts Section */}
            <div className='branding_conatiner'>
                <div className='brand_box' style={{ width: '70%', padding: 0 }}>
                    <div className='d-flex flex-column p-3'>
                        <label className='brand_head'>
                            Facts
                        </label>

                        <label className='sub_head'>
                            These are copied over directly as-is into your posts.
                        </label>
                    </div>

                    <hr />

                    <div>
                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                What is the name of what you're promoting?
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <input className='profile_edit_input' defaultValue={ProfileData.brand_name} onChange={(e) => handleFieldChange("brand_name", e.target.value)} name="Brand_name" type="text" />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.brand_name
                                                :
                                                "-"
                                        }
                                    </label>
                            }


                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Contact Email
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <input className='profile_edit_input' onChange={(e) => handleFieldChange("brand_email", e.target.value)} name="Brand_email" type="text" />
                                    :
                                    <label className='profile_details'>
                                        -
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Contact Phone
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <input className='profile_edit_input' defaultValue={ProfileData.brand_contact} onChange={(e) => handleFieldChange("brand_contact", e.target.value)} name="Brand_phone" type="text" />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.brand_contact
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Contact Address
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <input className='profile_edit_input' defaultValue={ProfileData.brand_address} onChange={(e) => handleFieldChange("brand_address", e.target.value)} name="Brand_address" type="text" />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.brand_address
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Website
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <input className='profile_edit_input' defaultValue={ProfileData.website} onChange={(e) => handleFieldChange("website", e.target.value)} name="website" type="text" />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.website
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Country
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <CountryDropdown
                                        countryList={country}
                                    />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.country
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Time Zone
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <TimezoneDropdown
                                        Timezonelist={TimeZone}
                                    />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.time_zone
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Caption-Suffix/Hashtags
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <input className='profile_edit_input' defaultValue={ProfileData.hashtags} onChange={(e) => handleFieldChange("hashtags", e.target.value)} name="Brand_hashtag" type="text" />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                <>
                                                    {
                                                        ProfileData.hashtags.length != 0
                                                            ?
                                                            ProfileData.hashtags.map((elem) => {
                                                                return elem
                                                            })
                                                            :
                                                            "-"
                                                    }
                                                </>
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Social Handle
                            </label>

                            {
                                FactsEditable
                                    ?
                                    <input className='profile_edit_input' defaultValue={ProfileData.social_handels} onChange={(e) => handleFieldChange("social_handles", e.target.value)} name="Brand_socialhandel" type="text" />
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.social_handels
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Call to Action (CTAs)
                            </label>

                            {
                                FactsEditable
                                    ?
                                    ProfileData.CTAs.map((elem, index) => {
                                        return (
                                            <div className='d-flex flex-row align-items-center w-100'>
                                                <input className='profile_edit_input w-100' onChange={(e) => handleEdit(index, e.target.value, "CTA")} defaultValue={elem} type="text" />
                                                <button className='minus_btn' onClick={() => { handleDelete(index, "CTA"); }}>
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        )
                                    })
                                    :
                                    <label className='profile_details'>
                                        {
                                            ProfileData != null
                                                ?
                                                ProfileData.CTAs.map((elem) => {
                                                    return (
                                                        <>
                                                            <label>
                                                                {elem}
                                                            </label>
                                                            <br />
                                                        </>
                                                    )
                                                })
                                                :
                                                "-"
                                        }
                                    </label>
                            }
                        </div>

                        <div style={{ textAlign: "right" }}>
                            {
                                FactsEditable
                                    ?
                                    <div className='w-100 text-right'>
                                        <button className='profile_edit_btn m-2' onClick={() => { GetProfileDetails(); setFactsEditable(false) }}>
                                            Cancel
                                        </button>

                                        <button disabled={isLoader} className='profile_edit_btn profile_save_btn m-2' onClick={() => { UpdateOnboardingData() }}>
                                            {
                                                isLoader
                                                    ?
                                                    <Spin indicator={antIcon} />
                                                    :
                                                    "Save"

                                            }
                                        </button>
                                    </div>
                                    :
                                    <button className='profile_edit_btn m-2' onClick={() => { setFactsEditable(true); dispatch(Countrychange(ProfileData.country)); dispatch(TimezoneChange(ProfileData.time_zone)) }}>
                                        <i class="fas fa-edit"></i>
                                        Edit
                                    </button>

                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Writing Style Section */}
            <div className='branding_conatiner'>
                <div className='brand_box' style={{ width: '70%', padding: 0 }}>
                    <div className='d-flex flex-column p-3'>
                        <label className='brand_head'>
                            Writing Style
                        </label>

                        <label className='sub_head'>
                            These are used to train the AI how to write.
                        </label>
                    </div>

                    <hr />

                    <div>
                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Tone
                            </label>

                            <label className='profile_details'>
                                {
                                    WritingEditable
                                        ?
                                        <textarea className='profile_edit_input profile_textarea' defaultValue={ProfileData.tone}></textarea>
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    ProfileData.tone
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Caption Sentence Length
                            </label>

                            <label className='profile_details'>
                                {
                                    WritingEditable
                                        ?
                                        <div className='d-flex length_btn_container'>
                                            <button onClick={() => setCaptionLength("Low")} className={captionlength == "Low" ? 'length_btn active_length' : 'length_btn'}>
                                                Low
                                            </button>

                                            <button onClick={() => setCaptionLength("Mid")} className={captionlength == "Mid" ? 'length_btn active_length' : 'length_btn'}>
                                                Mid
                                            </button>

                                            <button onClick={() => setCaptionLength("Low")} className={captionlength == "High" ? 'length_btn active_length' : 'length_btn'}>
                                                High
                                            </button>
                                        </div>
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    captionlength
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Emoji Level
                            </label>

                            <label className='profile_details'>
                                {
                                    WritingEditable
                                        ?
                                        <div className='d-flex length_btn_container'>
                                            <button onClick={() => setEmojiLevel("Low")} className={EmojiLevel == "Low" ? 'length_btn active_length' : 'length_btn'}>
                                                Low
                                            </button>

                                            <button onClick={() => setEmojiLevel("Mid")} className={EmojiLevel == "Mid" ? 'length_btn active_length' : 'length_btn'}>
                                                Mid
                                            </button>

                                            <button onClick={() => setEmojiLevel("High")} className={EmojiLevel == "High" ? 'length_btn active_length' : 'length_btn'}>
                                                High
                                            </button>
                                        </div>
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    EmojiLevel
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Self Promotional Level
                            </label>

                            <label className='profile_details'>
                                {
                                    WritingEditable
                                        ?
                                        <div className='d-flex length_btn_container'>
                                            <button onClick={() => setPromotionalLevel("Low")} className={PromotionalLevel == "Low" ? 'length_btn active_length' : 'length_btn'}>
                                                Low
                                            </button>

                                            <button onClick={() => setPromotionalLevel("Mid")} className={PromotionalLevel == "Mid" ? 'length_btn active_length' : 'length_btn'}>
                                                Mid
                                            </button>

                                            <button onClick={() => setPromotionalLevel("High")} className={PromotionalLevel == "High" ? 'length_btn active_length' : 'length_btn'}>
                                                High
                                            </button>
                                        </div>
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    PromotionalLevel
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Language
                            </label>

                            <label className='profile_details'>
                                {
                                    WritingEditable
                                        ?
                                        <input type="text" defaultValue={ProfileData.language} className='profile_edit_input' />
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    ProfileData.language
                                                    :
                                                    null
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Writing Samples
                            </label>

                            <label className='profile_details'>
                                {
                                    WritingEditable
                                        ?
                                        <>
                                            <textarea onChange={(e) => { ProfileData.writing_sample[0] = e.target.value }} defaultValue={ProfileData.writing_sample[0] != "null" ? ProfileData.writing_sample[0] : ""} className='profile_edit_input profile_textarea'></textarea>
                                        </>
                                        :
                                        <>
                                            {
                                                ProfileData == null
                                                    ?
                                                    "-"
                                                    :
                                                    ProfileData.writing_sample[0]
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div style={{ textAlign: "right" }}>
                            <div style={{ textAlign: "right" }}>
                                {
                                    WritingEditable
                                        ?
                                        <div className='w-100 text-right'>
                                            <button className='profile_edit_btn m-2' onClick={() => { GetProfileDetails(); setWitingEditable(false) }}>
                                                Cancel
                                            </button>

                                            <button disabled={isLoader} className='profile_edit_btn profile_save_btn m-2' onClick={() => { UpdateOnboardingData() }}>
                                                {
                                                    isLoader
                                                        ?
                                                        <Spin indicator={antIcon} />
                                                        :
                                                        "Save"

                                                }
                                            </button>
                                        </div>
                                        :
                                        <button className='profile_edit_btn m-2' onClick={() => { setWitingEditable(true) }}>
                                            <i class="fas fa-edit"></i>
                                            Edit
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Customer & Positioning Section */}
            <div className='branding_conatiner'>
                <div className='brand_box' style={{ width: '70%', padding: 0 }}>
                    <div className='d-flex flex-column p-3'>
                        <label className='brand_head'>
                            Customers & Positioning
                        </label>

                        <label className='sub_head'>
                            These are used to give the AI context about your business to better target your posts.
                        </label>
                    </div>

                    <hr />

                    <div>
                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                My Niche
                            </label>

                            <label className='profile_details'>
                                {
                                    CustomerEditable
                                        ?
                                        ProfileData.my_niche.map((elem, index) => {
                                            return (
                                                <div className='d-flex flex-row align-items-center w-100'>
                                                    <input className='profile_edit_input w-100' onChange={(e) => handleEdit(index, e.target.value, "niche")} defaultValue={elem} type="text" />
                                                    <button className='minus_btn' onClick={() => { handleDelete(index, "niche"); }}>
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </div>
                                            )
                                        })
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    ProfileData.my_niche.map((elem, index) => {
                                                        return (
                                                            <>
                                                                <label>
                                                                    {index + 1} {elem}
                                                                </label>
                                                                <br />
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Core Values
                            </label>

                            <label className='profile_details'>
                                {
                                    CustomerEditable
                                        ?
                                        ProfileData.core_values.map((elem, index) => {
                                            return (
                                                <div className='d-flex flex-row align-items-center w-100'>
                                                    <input className='profile_edit_input w-100' onChange={(e) => handleEdit(index, e.target.value, "core")} defaultValue={elem} type="text" />
                                                    <button className='minus_btn' onClick={() => { handleDelete(index, "core"); }}>
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </div>
                                            )
                                        })
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    ProfileData.core_values.map((elem, index) => {
                                                        return (
                                                            <>
                                                                <label>
                                                                    {index + 1} {elem}
                                                                </label>
                                                                <br />

                                                            </>
                                                        )
                                                    })
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Audience
                            </label>

                            <label className='profile_details'>
                                {
                                    CustomerEditable
                                        ?
                                        ProfileData.audience.map((elem, index) => {
                                            return (
                                                <div className='d-flex flex-row align-items-center w-100'>
                                                    <input className='profile_edit_input w-100' onChange={(e) => handleEdit(index, e.target.value, "audience")} defaultValue={elem} type="text" />
                                                    <button className='minus_btn' onClick={() => { handleDelete(index, "audience"); }}>
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </div>
                                            )
                                        })
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    ProfileData.audience.map((elem, index) => {
                                                        return (
                                                            <>
                                                                <label>
                                                                    {index + 1} {elem}
                                                                </label>
                                                                <br />

                                                            </>
                                                        )
                                                    })
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Objectives
                            </label>

                            <label className='profile_details'>

                                {
                                    CustomerEditable
                                        ?
                                        ProfileData.objectives.map((elem, index) => {
                                            return (
                                                <div className='d-flex flex-row align-items-center w-100'>
                                                    <input className='profile_edit_input w-100' onChange={(e) => handleEdit(index, e.target.value, "objectives")} defaultValue={elem} type="text" />
                                                    <button className='minus_btn' onClick={() => { handleDelete(index, "objectives"); }}>
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </div>
                                            )
                                        })
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    ProfileData.objectives.map((elem, index) => {
                                                        return (
                                                            <>
                                                                <label>
                                                                    {index + 1} {elem}
                                                                </label>
                                                                <br />
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div className='d-flex flex-column p-2'>
                            <label className='profile_head'>
                                Pain Points
                            </label>

                            <label className='profile_details'>
                                {
                                    CustomerEditable
                                        ?
                                        ProfileData.pain_points.map((elem, index) => {
                                            return (
                                                <div className='d-flex flex-row align-items-center w-100'>
                                                    <input className='profile_edit_input w-100' onChange={(e) => handleEdit(index, e.target.value, "painpoints")} defaultValue={elem} type="text" />
                                                    <button className='minus_btn' onClick={() => { handleDelete(index, "painpoints"); }}>
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </div>
                                            )
                                        })
                                        :
                                        <>
                                            {
                                                ProfileData != null
                                                    ?
                                                    ProfileData.pain_points.map((elem, index) => {
                                                        return (
                                                            <>
                                                                <label>
                                                                    {index + 1} {elem}
                                                                </label>
                                                                <br />
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    "-"
                                            }
                                        </>
                                }
                            </label>
                        </div>

                        <div style={{ textAlign: "right" }}>
                            <div style={{ textAlign: "right" }}>
                                {
                                    CustomerEditable
                                        ?
                                        <div className='w-100 text-right'>
                                            <button className='profile_edit_btn m-2' onClick={() => { GetProfileDetails(); setCustomerEditable(false) }}>
                                                Cancel
                                            </button>

                                            <button disabled={isLoader} className='profile_edit_btn profile_save_btn m-2' onClick={() => { UpdateOnboardingData() }}>
                                                {
                                                    isLoader
                                                        ?
                                                        <Spin indicator={antIcon} />
                                                        :
                                                        "Save"

                                                }
                                            </button>
                                        </div>
                                        :
                                        <button className='profile_edit_btn m-2' onClick={() => setCustomerEditable(true)}>
                                            <i class="fas fa-edit"></i>
                                            Edit
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
