import { createSlice } from "@reduxjs/toolkit";

const TimezoneSlice = createSlice({
    name: "TimezoneSlice",
    initialState: [],
    reducers: {
        TimezoneChange(state, action) {
            state.length = 0
            state.push(action.payload)
            // state=action.payload
            // return
        },
    }
})

export { TimezoneSlice }

export const { TimezoneChange } = TimezoneSlice.actions