import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { Linkedin, LoginFb, LoginYT, FacebookPages, LoginPintrest, InstagramLogin } from './Comman Methods/Connectprofile';

export default function ConnectModal() {

    const [Groups, setGroups] = useState(null);
    const [SelectedGroup, setSelectedGroup] = useState();

    const GetGroups = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/group/get`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setGroups(res.data.groups)
                setSelectedGroup(res.data.groups[0].name)
                // if (localStorage.getItem("oolook_default_group") == undefined) {
                //     GetGroupDetails(res.data.groups[0].name)
                // } else {
                //     GetGroupDetails(localStorage.getItem("oolook_default_group"))
                // }

                // setSelectedGroup()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(()=>{
        GetGroups()
    },[])

    return (
        <>
            <div className='grp-modal-head mt-3'>Select a network to connect a profile</div>
            <span>
                If you have several accounts to connect, don’t worry  we’ll bring you back to this screen after each profile is set up.
            </span>

            <div className='d-flex flex-column w-50 mt-5'>
                <label className='Group_label'>
                    In Group
                </label>

                <select className='Group_dropdown' value={SelectedGroup} onChange={(e) => { localStorage.setItem("selected_group", e.target.value); setSelectedGroup(e.target.value) }}>
                    {
                        Groups != null
                            ?
                            Groups.map((elem) => {
                                return (
                                    <option value={elem.name}>{elem.name}</option>
                                )
                            })
                            :
                            null
                    }
                </select>
            </div>

            <div className='modalgrp-div'>
                <div className='modal-card d-flex flex-column justify-content-around'>
                    <div className='card-img-div'>
                        <img src={require('../Assets/Icons/Facebook.png')} className='modal-img' />
                        <div className='modal-text'>Facebook</div>
                    </div>

                    <div className='modal-connect'>
                        <button className='connect-btn' onClick={() => {
                            LoginFb()
                        }}>Connect</button>
                    </div>
                </div>

                <div className='modal-card d-flex flex-column justify-content-around'>
                    <div className='card-img-div'>
                        <img src={require('../Assets/Icons/Instagram.png')} className='modal-img' />
                        <div className='modal-text'>Instagram</div>
                    </div>

                    <div className='modal-connect'>
                        <button className='connect-btn' onClick={() => { InstagramLogin() }}>Connect</button>
                    </div>
                </div>

                <div className='modal-card d-flex flex-column justify-content-around'>
                    <div className='card-img-div'>
                        <img src={require('../Assets/Icons/Pintrest.png')} className='modal-img' />
                        <div className='modal-text'>Pinterest</div>
                    </div>

                    <div className='modal-connect'>
                        <button className='connect-btn' onClick={() => { LoginPintrest() }}>Connect</button>
                    </div>
                </div>

                <div className='modal-card d-flex flex-column justify-content-around'>
                    <div className='card-img-div'>
                        <img src={require('../Assets/Icons/GB.png')} className='modal-img' />
                        <div className='modal-text'>Google My Business</div>
                    </div>

                    <div className='modal-connect'>
                        <button className='connect-btn'>Connect</button>
                    </div>
                </div>
            </div>

            <div className='modalgrp-div2 d-flex justify-content-center'>
                <div className='modal-card d-flex flex-column justify-content-around'>
                    <div className='card-img-div'>
                        <img src={require('../Assets/Icons/Twitter.png')} className='modal-img' />
                        <div className='modal-text'>X</div>
                    </div>

                    <div className='modal-connect'>
                        <button className='connect-btn'>Connect</button>
                    </div>
                </div>

                <div className='modal-card d-flex flex-column justify-content-around'>
                    <div className='card-img-div'>
                        <img src={require('../Assets/Icons/LinkedIn.png')} className='modal-img' />
                        <div className='modal-text'>Linkdin</div>
                    </div>

                    <div className='modal-connect'>
                        <button className='connect-btn' onClick={() => Linkedin()}>Connect</button>
                    </div>
                </div>

                <div className='modal-card d-flex flex-column justify-content-around'>
                    <div className='card-img-div'>
                        <img src={require('../Assets/Icons/Youtube.png')} className='modal-img' />
                        <div className='modal-text'>YouTube</div>
                    </div>

                    <div className='modal-connect'>
                        <button className='connect-btn' onClick={() => { LoginYT() }}>Connect</button>
                    </div>
                </div>
            </div>
        </>
    )
}
