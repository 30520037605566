import { createSlice } from "@reduxjs/toolkit";

const CountrySlice = createSlice({
    name: "CountrySliceSlice",
    initialState: [],
    reducers: {
        Countrychange(state, action) {
            state.length = 0
            state.push(action.payload)
            // state=action.payload
            // return
        },
    }
})

export { CountrySlice }

export const { Countrychange } = CountrySlice.actions