import React from 'react'
import Sidebar from './Sidebar'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Approval from './Approval'
import Calender from './Calender'
import Rejected from './Rejected'
import Newpost from './Newpost'
import Billing from './Billing'
import ProfileReports from './ProfileReports'
import Inbox from './Inbox'
import ConnectProfile from './ConnectProfile'
import CreateCampagin from './CreateCampagin'
import PublishPage from './PublishPage'
import AssetsUpload from './Assets/AssetsUpload'
import AccountSetting from './AccountSetting'
import GroupsProfile from './GroupsProfile'
import Message from './Message'
import Competitor from './Competitor'
import PostReport from './PostReport'
import Facebook from './Redirectpage/Facebook'
import Connection from '../ConnectionPages/Connection'
import Branding from './Branding'
import Topics from './Topics'
import BusinessProfile from './ProfileBusiness'


export default function Dashboard() {
  return (
    <div className='row mx-0'>
      <div className='col-sm-1 px-0'>
        <Sidebar />
      </div>

      <div className='col-sm-11 Dashboard'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Inbox" element={<Inbox />} />
          <Route path='/Publishing' element={<Calender />} >
          </Route>
          <Route path="/Createpost" element={<Newpost />} />
          <Route path="/PublishPost" element={<PublishPage />} />
          <Route path="/Assets" element={<AssetsUpload/>}/>
          <Route path="/Approval" element={<Approval />} />
          <Route path="/Rejected" element={<Rejected />} />
          <Route path="/Reports" element={<ProfileReports />} />
          <Route path="/Billing" element={<Billing />} />
          <Route path="/Profile" element={<ConnectProfile />} />
          <Route path="/Campagin" element={<CreateCampagin />} />
          <Route path="/AccountSetting" element={<AccountSetting />} />
          <Route path="/GroupsProfile" element={<GroupsProfile />} />
          <Route path="/Message" element={<Message/>}/>
          <Route path="/Competitor" element={<Competitor/>}/>
          <Route path="/Postreports" element={<PostReport/>}/>
          <Route path="/ConnectPages" element={<Connection/>}/>
          <Route path="/Branding" element={<Branding/>}/>
          <Route path="/Topics" element={<Topics/>}/>
          <Route path="/Businessprofile" element={<BusinessProfile/>}/>
        </Routes>
      </div>
    </div>
  )
}
