import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Countrychange } from '../Components/store/Slices/CountrySlice';

export default function CountryDropdown({ countryList }) {

    const [listShow, setListShow] = useState(false);
    const [ListData, setListData] = useState(countryList);
    const [search, setSearch] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const selector=useSelector((state)=>{return state});
    const dispatch = useDispatch();

    const SearchCountry = (key) => {
        if (key.key == "Enter") {
            const searchWords = search.toLowerCase().split(' ').filter(word => word);

            const filtered = ListData.filter(country =>
                searchWords.every(word =>
                    String(country).toLowerCase().includes(word)
                )
            );



            if (filtered.length == 0) {
                setListData(countryList)
            }
            else {
                setListData(filtered)
            }

        }
    }

    return (
        <div className='w-100'>
            <button onClick={() => setListShow(!listShow)} className='d-flex justify-content-between align-items-center w-100 form_input'>
                <div>
                    {selector.Country[0]}
                </div>

                <div className='downarrow_icon'>
                    <i class="fa fa-angle-down fa-xs"></i>
                </div>
            </button>

            {
                listShow
                    ?
                    <div className='drop_list'>

                        <input type="text" onKeyDown={(e) => { SearchCountry(e) }} onChange={(e) => { setSearch(e.target.value) }} placeholder='Search' className='mx-2 mt-1 w-75 form_input' />
                        <ul>
                            {
                                ListData.map((elem) => {
                                    return (
                                        <li>
                                            <button onClick={() => { setSelectedCountry(elem); setListShow(false); setListData(countryList); dispatch(Countrychange(elem)) }}>
                                                {elem}
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    :
                    null
            }


        </div>
    )
}
