import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk';
import WebFont from "webfontloader";

export default function AssetsData() {

    const colorPalette = [
        ["#737272", "#E58888", "#36A769"],
        ["#643434", "#8888E5", "#3654A7"],
        ["#C56592", "#E588E3", "#85A736"],
        ["#36C490", "#1F2746", "#351BFC"],
        ["#AAFFAF", "#DEF6AA", "#A1C2B0"],
        ["#4CA9E7", "#194B36", "#FFFDC4"],
        ["#FFA0A0", "#EAEAEA", "#B2F0CE"],
        ["#FF3C3C", "#E58888", "#D1B3FF"]
    ]

    const navigate = useNavigate();


    const LogoRef = useRef(null);
    const headshotref = useRef(null);

    const [fileType, setFileType] = useState();

    const [LogoProgess, setLogoProgess] = useState(0);
    const [opencolor, setOpenColor] = useState(false);
    const [openBgcolor, setOpenBgcolor] = useState(false);
    const [opentextcolor, setOpenTextcolor] = useState(false);
    const [showPalette, setShowpalette] = useState(false);
    const [FontList, setFontList] = useState(null);
    const [selectedFont, setSelectedFont] = useState();
    const [bodySelectedFont,setBodySelectedFont]=useState();
    const [showHeaderFont, setShowHeaderFont] = useState(false);
    const [showBodyFont, setShowbodyFont] = useState(false);

    // parameteres
    const [Headshot, setHeadshot] = useState(null);
    const [LogoPreview, setLogoPeview] = useState();
    const [HeadFont, setHeadFont] = useState('');
    const [BodyFont, setBodyFont] = useState('');
    const [brandColor, setBrandcolor] = useState("#ffffff");
    const [bgColor, setBgColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#ffffff");

    const [uniqueID, setUniqueID] = useState();

    const [isLoader, setIsLoader] = useState(false);


    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;


    const uploadAssets = async () => {
        setIsLoader(true);

        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/add-onboarding3`, {
            brand_logo : LogoPreview,
            brand_color : brandColor,
            brand_headshot : Headshot,
            header_font : HeadFont,
            body_font : BodyFont,
            bg_color : bgColor,
            text_color :textColor,
            palette : "palette",
            unique_id :uniqueID,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                UpdateStatus()
                setIsLoader(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoader(false)
                message.error("There is some error please try again")
            })
    }

    const GenerateUniqueID = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/unique/generate-id`)
            .then((res) => {
                setUniqueID(res.data.id)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    async function UpdateStatus() {
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/user/update-status`, {
            status: 'completed'
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then((res) => {
                navigate("/");
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const BrandLogouploadStocket = async (e) => {

        if (e.target.files[0]['type'] == undefined) {
            message.warning("Upload an Image")
        }
        else {
            if (e.target.files[0]['type'].split('/')[0] == "image") {
                setFileType('photo');
            }
            else {
                setFileType('video');
            }
        }

        let filename;
        const file = e.target.files[0];
        if (file == undefined) {
            message.warning("Upload a File");
            return;
        }
        else {
            filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
        }


        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'assets-libraries',
            Key: filename,
            Body: file,
        };

        const options = {
            partSize: 10 * 1024 * 1024, // Set the part size for multipart upload
            queueSize: 1 // Number of concurrent uploads
        };

        const upload = s3.upload(params, options);

        upload.on('httpUploadProgress', (progressData) => {
            const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
            // setProgress(percentageProgress);
            setLogoProgess(percentageProgress)

        });

        s3.upload(params, (err, data) => {
            if (err) {
                console.error(err);
                alert('Failed to upload file.');
            }
            else {
                setLogoPeview(data.Location)
            }
        });

        try {
            await upload.promise().then(() => {
                message.success(`File Uploaded`);
            })

        } catch (error) {
            console.error('Upload failed', error);
        }


    }

    const BrandheadshortloadStocket = async (e) => {
        if (e.target.files[0]['type'] == undefined) {
            message.warning("Upload an Headshot Image")
        }
        else {
            if (e.target.files[0]['type'].split('/')[0] == "image") {
                setFileType('photo');
            }
            else {
                setFileType('video');
            }
        }

        let filename;
        const file = e.target.files[0];
        if (file == undefined) {
            message.warning("Upload a File");
            return;
        }
        else {
            filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
        }


        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'assets-libraries',
            Key: filename,
            Body: file,
        };

        const options = {
            partSize: 10 * 1024 * 1024, // Set the part size for multipart upload
            queueSize: 1 // Number of concurrent uploads
        };

        const upload = s3.upload(params, options);

        upload.on('httpUploadProgress', (progressData) => {
            const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
            // setProgress(percentageProgress);
            setLogoProgess(percentageProgress)

        });

        s3.upload(params, (err, data) => {
            if (err) {
                console.error(err);
                alert('Failed to upload file.');
            }
            else {
                setHeadshot(data.Location)
            }
        });

        try {
            await upload.promise().then(() => {
                message.success(`File Uploaded`);
            })

        } catch (error) {
            console.error('Upload failed', error);
        }
    }


    const GetAllgoogleFont = async () => {
        await axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONT}`)
            .then((res) => {
                const fontNames = res.data.items.map((font) => font.family);
                setFontList(fontNames);
                setSelectedFont(fontNames[0]);
                setBodySelectedFont(fontNames[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        GenerateUniqueID();
        GetAllgoogleFont();
    }, [])

    useEffect(() => {
        if (selectedFont) {
            WebFont.load({
                google: {
                    families: [selectedFont,bodySelectedFont],
                },
            });
        }
    }, [selectedFont,bodySelectedFont])

    return (
        <div className='bg-images'>
            <div className='onbording_box brand_onboarding_box'>
                <div className='brand_box'>
                    <div className='d-flex flex-column px-2'>
                        <label className='brand_head'>
                            Your Brand
                        </label>

                        <label className='sub_head'>
                            Choose how your business presents itself. Oolook uses your brand to give a unique look to the posts it generates.
                        </label>
                    </div>

                    <hr />

                    <div>
                        <div className='d-flex justify-content-between w-50'>
                            <div className='d-flex flex-column'>
                                <label className='input_label'>
                                    Logo
                                </label>

                                <div className='logo_section' onClick={() => { LogoRef.current.click() }}>
                                    {
                                        LogoPreview
                                            ?
                                            <img src={LogoPreview} style={{ objectFit: 'contain', width: '76px', height: '76px' }} />
                                            :
                                            <i class="fa-solid fa-pencil"></i>
                                    }
                                    <button className='cross_btn'>
                                        <i class="fa fa-times-circle"></i>
                                    </button>
                                </div>

                                <input type="file" accept="image/*" onChange={(e) => { BrandLogouploadStocket(e); }} style={{ display: 'none' }} ref={LogoRef} />
                            </div>

                            <div className='d-flex flex-column'>
                                <label className='input_label'>
                                    Headshot
                                </label>

                                <div className='logo_section' onClick={() => headshotref.current.click()}>
                                    {
                                        Headshot != null
                                            ?
                                            <img src={Headshot} style={{ objectFit: 'contain', width: '76px', height: '76px' }} />
                                            :
                                            <i class="fa-solid fa-pencil"></i>
                                    }

                                    {
                                        Headshot != null
                                            ?
                                            <button className='cross_btn' onClick={() => setHeadshot(null)}>
                                                <i class="fa fa-times-circle"></i>
                                            </button>
                                            :
                                            null
                                    }
                                </div>

                                <input type="file" ref={headshotref} accept="image/*" onChange={(e) => { BrandheadshortloadStocket(e); }} style={{ display: 'none' }} />

                            </div>
                        </div>

                        <div>
                            <div className='d-flex flex-column'>
                                <label className='input_label'>
                                    Colors
                                </label>

                                <label className='input_sub_label'>
                                    Select the colors you want to use for your posts.
                                </label>

                                <div className='d-flex justify-content-around mt-2 color_section'>
                                    <button className='d-flex align-items-center color_boxs' onClick={() => { setOpenColor(!opencolor); console.log(brandColor) }}>

                                        <span className='color_box mx-1' style={{ backgroundColor: `${brandColor}` }}></span>

                                        <label className='color_label'>
                                            Brand Color
                                        </label>
                                    </button>
                                    {
                                        opencolor &&
                                        <div className='color_picker'>
                                            <SketchPicker color={brandColor} onChangeComplete={(e) => { setBrandcolor(e.hex) }} />
                                        </div>
                                    }

                                    <button className='d-flex align-items-center color_boxs' onClick={() => { setOpenBgcolor(!openBgcolor) }}>
                                        <span className='color_box mx-1' style={{ backgroundColor: `${bgColor}` }}></span>
                                        <label className='color_label'>
                                            Background Color
                                        </label>
                                    </button>

                                    {
                                        openBgcolor &&
                                        <div className='color_picker'>
                                            <SketchPicker color={bgColor} onChangeComplete={(e) => { setBgColor(e.hex) }} />
                                        </div>
                                    }

                                    <button className='d-flex align-items-center color_boxs' onClick={() => { setOpenTextcolor(!opentextcolor) }}>
                                        <span className='color_box mx-1' style={{ backgroundColor: `${textColor}` }}></span>
                                        <label className='color_label'>
                                            Text Color
                                        </label>
                                    </button>
                                    {
                                        opentextcolor &&
                                        <div className='color_picker'>
                                            <SketchPicker onChangeComplete={(e) => { setTextColor(e.hex) }} />
                                        </div>
                                    }
                                </div>

                                <div>
                                    <div>
                                        <button className='add_palette_btn mt-2' onClick={() => { setShowpalette(!showPalette) }}>
                                            <label>
                                                Add a Palette
                                            </label>

                                            <img src={require('../Assets/color_pallet.png')} />
                                        </button>
                                    </div>
                                    {
                                        showPalette &&
                                        <div className='palette_container'>
                                            <ul className='colorpalette_list w-25 d-flex flex-column align-items-center mt-2'>
                                                {
                                                    colorPalette.map((elem) => {
                                                        return (
                                                            <li>
                                                                <button className='d-flex p-1 justify-content-center align-items-center color_palette_box' onClick={() => { setBrandcolor(elem[0]); setBgColor(elem[1]); setTextColor(elem[2]) }}>
                                                                    {
                                                                        elem.map((colors) => {
                                                                            return (
                                                                                <span className='color_box mx-1' style={{ backgroundColor: `${colors}` }}></span>
                                                                            )
                                                                        })
                                                                    }
                                                                </button>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>

                                    }
                                </div>

                            </div>
                        </div>

                        <div>
                            <div className='d-flex flex-column'>
                                <label className='input_label'>
                                    Font
                                </label>

                                <label className='input_sub_label'>
                                    Choose fonts to use on all your posts.
                                </label>
                            </div>

                            <div className='d-flex flex-column'>
                                <label className='font_head_label'>
                                    Header Font
                                </label>

                                <div style={{ position: 'relative', width: '100%' }}>
                                    <button className='w-100 drop_btn' onClick={() => { setShowHeaderFont(!showHeaderFont) }}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label>{HeadFont}</label>

                                            {
                                                showHeaderFont
                                                    ?
                                                    <i class="fa fa-angle-up"></i>
                                                    :
                                                    <i class="fa fa-angle-down"></i>
                                            }
                                        </div>
                                    </button>

                                    {
                                        showHeaderFont
                                            ?
                                            <ul className='font_list'>
                                                {
                                                    FontList != null
                                                        ?
                                                        FontList.map((Fontelem) => {
                                                            return (
                                                                <li style={{ fontFamily: Fontelem }} onClick={() => { setSelectedFont(Fontelem); setHeadFont(Fontelem); }}>
                                                                    {Fontelem}
                                                                </li>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </ul>
                                            :
                                            null
                                    }
                                </div>

                            </div>

                            <div className='d-flex flex-column mt-2'>
                                <label className='font_head_label'>
                                    Body Font
                                </label>

                                <div style={{ position: 'relative', width: '100%' }}>
                                    <button className='w-100 drop_btn' onClick={() => { setShowbodyFont(!showBodyFont) }}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label>{BodyFont}</label>

                                            {
                                                showBodyFont
                                                    ?
                                                    <i class="fa fa-angle-up"></i>
                                                    :
                                                    <i class="fa fa-angle-down"></i>
                                            }
                                        </div>
                                    </button>

                                    {
                                        showBodyFont
                                            ?
                                            <ul className='font_list'>
                                                {
                                                    FontList != null
                                                        ?
                                                        FontList.map((Fontelem) => {
                                                            return (
                                                                <li style={{ fontFamily: Fontelem }} onClick={() => { setSelectedFont(Fontelem); setBodyFont(Fontelem) }}>
                                                                    {Fontelem}
                                                                </li>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </ul>
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            <div className='d-flex justify-content-between mt-3'>
                                <button className='back_btn' onClick={() => { navigate('/Onboarding') }}>
                                    Back
                                </button>

                                <button className='next_btn' onClick={()=>{uploadAssets()}}>
                                    {
                                        isLoader
                                            ?
                                            <Spin indicator={antIcon} />
                                            :
                                            "Next"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
