import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { message, Progress } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import { DatePicker } from 'antd';
import { FileUploader } from "react-drag-drop-files";
import FacebookModal from './Social Desings/FacebookModal';
import InstagarmModal from './Social Desings/InstagarmModal';
import { Socket, io } from 'socket.io-client';
import { Spin, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { ConvertDateFormate } from './ConvertDateFormate';
import { stubTrue } from 'lodash';
import AWS from 'aws-sdk';
import Cookies from 'js-cookie';
import { LoadingOutlined } from '@ant-design/icons';
import YoutubeModal from './Social Desings/YoutubeModal';
import Pinerest from './Social Desings/Pinerest';
import { useNavigate } from 'react-router-dom';
import Images from '../Comman Components/Images';
import UserName from '../Comman Components/UserName';
import PinName from '../Comman Components/Pinterest Componets/PinName';
import PinImage from '../Comman Components/Pinterest Componets/PinImage';
import dayjs from 'dayjs'

export default function PublishPage() {

    const navigate = useNavigate();

    const [Paltform, setPlatform] = useState('Facebook');
    const [access_token, setAccessToken] = useState();
    const [yt_access_token, setYt_access] = useState('');
    const [messages, setMessage] = useState("");
    const [Fileupload, setFileUpload] = useState();
    const [ytexpiry, setYTexpiry] = useState();
    const [emoji, setEmoji] = useState();
    const [IG_ID, setIGID] = useState();
    const uploadfile = useRef(null);
    const messageRef = useRef(null);
    const [datepickertoggle, setDatePickerToggle] = useState(false);
    const [PhotoPreivew, setPhotoPreview] = useState([]);
    const [PublishType, setPublishType] = useState('IMAGE');
    const [uploadMenu, setUploadMenu] = useState(false);
    const [uploadedImg, setUploadImg] = useState([]);
    const [postBtnEnable, setPostButtonEnable] = useState(true);
    const [progess, setProgess] = useState(0);
    const [isLoader, setLoader] = useState(false);
    const [fileType, setFileType] = useState('text');
    const [APIwait, setAPIWait] = useState(false);
    const [singlePage, setSinglepage] = useState([]);
    const [boardList, setBoardList] = useState(false);
    const [newBoardName, setNewBoardName] = useState(null);
    const [newBoardDes, setNewBoardDesc] = useState(null);
    const [BoardID, setBoardID] = useState();
    const [DefaultBorad, setDefaultBoard] = useState();
    const [boardTitle, setBoardTitle] = useState();
    const [avalibaleProfile, setAvaliableProifle] = useState(null);
    const [showList, setShowList] = useState(false);
    const [SelectedPlatform, setSelectedPlatform] = useState(null);
    const [PinterestPlatform, setPinPlatform] = useState(null);

    const [scheduleDates, setScheduleDate] = useState('');

    const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />;


    const location = useLocation();

    // Youtube option
    const [title, setTitle] = useState("");
    const [tags, setTags] = useState();
    const [category, setCategory] = useState();
    const [privacyStatus, setprivacyStatus] = useState();
    const [scope, setScope] = useState();
    const [onboarding, setOnboarding] = useState(null);
    const [captionLoad, setCaptionLoad] = useState(false);
    const [PintBoardList, setPintBoardList] = useState();
    const [openCreateboard, setopenCreateboard] = useState(false);

    // Facebook Option
    const [description, setDesc] = useState("");

    const fileTypes = ["JPG", "PNG", "GIF"];


    const MonthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Platform Posting
    const Post = () => {
        // setLoader(true)
        setAPIWait(true)
        var token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token")
        }

        let PlatformName = [];

        let boardId = '';

        let platformSelected = [];

        let fileTypes = '';

        let scheduledTime = new Date(scheduleDates);
        const changescheduleDate = scheduledTime.getFullYear() + "-" + ("0" + (scheduledTime.getMonth() + 1)).slice(-2) + "-" + ("0" + (scheduledTime.getDate())).slice(-2) + "T" + ("0" + (scheduledTime.getUTCHours())).slice(-2) + ":" + ("0" + (scheduledTime.getUTCMinutes())).slice(-2) + ":" + "00";


        SelectedPlatform.map((elem) => {
            if (elem.platform == "FACEBOOK") {
                platformSelected.push(elem.platform_pageId);
            }
            else {
                platformSelected.push(elem.platform_id);
            }

            PlatformName.push(elem.platform)
        })

        if (BoardID != undefined) {
            boardId = BoardID.id
        }

        if (PublishType.toLocaleLowerCase() === "image") {
            fileTypes = 'image'
        } else if (PublishType.toLocaleLowerCase() === "reels") {
            fileTypes = "video"
        }
        else {
            fileTypes = "stories"
        }



        if (new Date(scheduleDates) > new Date()) {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/group/schedule-posting`, {
                "groupName": localStorage.getItem("oolook_default_group"),
                "platforms": [...new Set(PlatformName)],
                "media_type": fileTypes,
                "media_url": PhotoPreivew[0],
                "message": messages,
                "title": title,
                "description": description,
                "selectedProfiles": platformSelected, // You can specify selected profiles if needed
                "board_id": boardId,// Provide the Pinterest board ID
                "scheduledTime": changescheduleDate,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    setLoader(false);
                    setAPIWait(false)
                    message.success("Post Scheduled");
                    // navigate('/Dashboard/Publishing');
                })
                .catch((err) => {
                    setLoader(false)
                    setAPIWait(false)
                    message.error("There is some error try again");
                    console.log(err)
                })
        }
        else {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/group/group-posting`, {
                "groupName": localStorage.getItem("oolook_default_group"),
                "platforms": [...new Set(PlatformName)],
                "media_type": fileTypes,
                "media_url": PhotoPreivew[0],
                "message": messages,
                "title": title,
                "description": description,
                "selectedProfiles": platformSelected, // You can specify selected profiles if needed
                "board_id": boardId,// Provide the Pinterest board ID
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    setLoader(false);
                    message.success("Posted");
                    navigate('/Dashboard/Publishing');
                })
                .catch((err) => {
                    setLoader(false)
                    message.error("There is some error try again");
                    console.log(err)
                })
        }

    }

    // Add emoji in message
    const AddEmoji = (emojiObject) => {
        const cursor = messageRef.current.selectionStart;
        const text = messages.slice(0, cursor) + emojiObject.emoji + messages.slice(cursor);
        console.log(text)
        setMessage(text);
        setEmoji(false)
    }

    // Change Date formate
    const changeDateFormate = (date) => {
        const newscheduledDate = new Date(date);
        // 2024-01-17T22:50:00Z
        // const changescheduleDate = newscheduledDate.getFullYear() + "-" + ("0" + (newscheduledDate.getMonth())).slice(-2) + "-" +("0" + (newscheduledDate.getDate())).slice(-2) + "T" + newscheduledDate.getHours() + ":" + newscheduledDate.getMinutes() + ":" + "00Z";

        setScheduleDate(newscheduledDate)
        // console.log(newscheduledDate)
        // console.log(ConvertDateFormate(date))
        // console.log(scheduleDates)
    }


    // Upload image to AWS
    const uploadStocket = async (e) => {
        if (PublishType == "REELS") {
            if ((e.target.files[0].name).split(".").slice(-1) != 'mp4') {
                message.error("Please Upload the Video in mp4 formate")
            }
            else {
                if (e.target.files[0]['type'].split('/')[0] == "image") {
                    setFileType('photo')
                }
                else {
                    setFileType('video')
                }

                let filename;
                const file = e.target.files[0];
                if (file == undefined) {
                    message.warning("Upload a Video");
                    return;
                }
                else {
                    filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
                }


                const s3 = new AWS.S3({
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
                    region: process.env.REACT_APP_REGION,
                });

                const params = {
                    Bucket: 'oolook-posting',
                    Key: filename,
                    Body: file,
                };

                const options = {
                    partSize: 10 * 1024 * 1024,
                    queueSize: 1
                };

                const upload = s3.upload(params, options);

                upload.on('httpUploadProgress', (progressData) => {
                    const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
                    setProgess(percentageProgress);

                });

                s3.upload(params, (err, data) => {
                    if (err) {
                        console.error(err);
                        alert('Failed to upload file.');
                    }
                    else {
                        PhotoPreivew.push(data.Location);
                        setUploadMenu(false)
                    }
                });

                try {
                    await upload.promise().then(() => {
                        message.success(`File Uploaded`);
                    })

                } catch (error) {
                    console.error('Upload failed', error);
                }
            }

        }
        else {
            if (e.target.files[0]['type'].split('/')[0] == "image") {
                setFileType('photo')
            }
            else {
                setFileType('video')
            }

            let filename;
            const file = e.target.files[0];
            if (file == undefined) {
                message.warning("Upload a Video");
                return;
            }
            else {
                filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
            }


            const s3 = new AWS.S3({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
                region: process.env.REACT_APP_REGION,
            });

            const params = {
                Bucket: 'oolook-posting',
                Key: filename,
                Body: file,
            };

            const options = {
                partSize: 10 * 1024 * 1024,
                queueSize: 1
            };

            const upload = s3.upload(params, options);

            upload.on('httpUploadProgress', (progressData) => {
                const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
                setProgess(percentageProgress);

            });

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error(err);
                    alert('Failed to upload file.');
                }
                else {
                    PhotoPreivew.push(data.Location);
                    setUploadMenu(false)
                }
            });

            try {
                await upload.promise().then(() => {
                    message.success(`File Uploaded`);
                })

            } catch (error) {
                console.error('Upload failed', error);
            }
        }
    }


    const GetOnboardingData = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/onboarding/get-onboarding`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                // console.log(res)
                setOnboarding(res.data.OnBoarding)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Generate Captions
    const GenerateCaption = () => {
        if (PhotoPreivew.length == 0) {
            message.error("Please Upload Image")
        }
        else {
            setCaptionLoad(true)
            axios.post(`https://python.oolook.in/generate_caption`, {
                "image_url": PhotoPreivew[0]
            })
                .then((res) => {
                    // console.log((res.data.captions))
                    setMessage(res.data.captions)
                    setCaptionLoad(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    // Delete Images
    const DeleteImage = (images) => {
        if (Paltform == "Facebook") {
            setPhotoPreview([])
        }
        if (Paltform == "Instagram") {
            if (PublishType == "Multiple") {
                PhotoPreivew.pop(PhotoPreivew.indexOf(images));
                setUploadMenu(true)
            }
            else {
                setPhotoPreview([]);
            }
        }
    }

    // Get pinterest board list
    const GetBoard = async (boardID) => {
        var token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        await axios.post(`${process.env.REACT_APP_BACKEND_API}/pinterest/get-board`, {
            user_id: boardID
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setPintBoardList(res.data.items)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Pinetrest board create
    const CreateBoard = async () => {
        var token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (newBoardName == null) {
            message.error("Enter Board Name")
        }
        else {
            await axios.post(`${process.env.REACT_APP_BACKEND_API}/pinterest/create-board`, {
                user_id: DefaultBorad,
                name: newBoardName,
                description: newBoardDes,
                privacy: "PUBLIC"//PRIVATE
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    console.log(res)
                    setopenCreateboard(false);
                    message.success("New Board Created");
                    GetBoard();
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    // Get Selected Group Details
    const GetGroups = () => {
        var token;
        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/get-particular`, {
            groupName: localStorage.getItem("oolook_default_group")
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setAvaliableProifle(res.data.group.connected_accounts);

                var selePlatform;

                selePlatform = (res.data.group.connected_accounts).slice(0);

                const indexToRemove = selePlatform.findIndex(item => item.platform === 'PINTEREST');

                // If a Pinterest object is found, remove it
                if (indexToRemove !== -1) {
                    selePlatform.splice(indexToRemove, 1);
                }

                setSelectedPlatform(selePlatform);

                selePlatform.map((elem) => {
                    if (elem.platform == "PINTEREST") {
                        // console.log(elem)
                        setDefaultBoard(elem.platform_id)
                        GetBoard(elem.platform_id)
                    }
                })
                // console.log()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // check value for checkbox
    const CheckPlatform = (value) => {
        if (value.platform == "FACEBOOK") {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_pageId == value.platform_pageId) {
                    return true;
                }
            }
        }
        else if (value.platform == "INSTAGRAM") {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_id == value.platform_id) {
                    return true;
                }
            }
        }
        else if (value.platform == "PINTEREST") {
            GetBoard(value.platform_id)
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_id == value.platform_id) {
                    return true;
                }
            }
        }
    }

    // Push Data to selected array
    const ChangePaltform = (value, checked) => {
        if (checked) {
            SelectedPlatform.push(value);
            if (value.platform == "PINTEREST") {
                setDefaultBoard(value.platform_id)
            }
        }
        else {
            if (value.platform == "FACEBOOK") {
                SelectedPlatform.map(item => {
                    if (item.platform_pageId === value.platform_pageId) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        // console.log(item.platform_id);
                    }
                })
            }
            if (value.platform == "INSTAGRAM") {
                SelectedPlatform.map(item => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                    }
                })
            }
            if (value.platform == "PINTEREST") {
                SelectedPlatform.map(item => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                    }
                })
            }
        }
    }

    // Show List of Platforms
    function ShowProfiles() {
        return (
            avalibaleProfile.filter(profile => profile.platform === 'FACEBOOK' || 'INSTAGRAM' || 'PINTEREST').map((elem, index) => {
                return (
                    <li>
                        <div className='d-flex align-items-center justify-content-between pages_list_view'>
                            <div className='d-flex align-items-center w-50'>
                                {
                                    elem.platform == "FACEBOOK"
                                        ?
                                        <img src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`} className='profile_img_post' />
                                        :
                                        elem.platform == "INSTAGRAM"
                                            ?
                                            <>
                                                {<Images Id={elem.platform_id} />}
                                            </>
                                            :
                                            <PinImage Id={elem.platform_id} />
                                }
                                {
                                    elem.platform == "FACEBOOK"
                                        ?
                                        <i class="fa-brands fa-facebook mx-2" style={{ color: '#1877F2' }}></i>
                                        :
                                        elem.platform == "INSTAGRAM"
                                            ?
                                            <i class="fa-brands fa-instagram mx-2" style={{ color: '#E1306C' }}></i>
                                            :
                                            elem.platform == "PINTEREST"
                                                ?
                                                <i class="fa-brands fa-pinterest mx-2" style={{ color: '#E60023' }}></i>
                                                :
                                                null
                                }
                                {
                                    elem.platform == "FACEBOOK"
                                        ?
                                        <>
                                            {elem.profile}
                                        </>
                                        :
                                        elem.platform == "INSTAGRAM"
                                            ?
                                            <UserName Id={elem.platform_id} />
                                            :
                                            <PinName Id={elem.platform_id} />
                                }
                            </div>

                            <div>
                                <input type="checkbox" defaultChecked={CheckPlatform(elem)} onChange={(e) => { ChangePaltform(elem, e.target.checked); }} />
                            </div>
                        </div>
                    </li>
                )
            })
        )
    }


    useEffect(() => {
        if (location.state.date === new Date().getDate()) {
            if ((location.state.month + 1) === new Date().getMonth() + 1) {
                changeDateFormate(new Date());

            }
        }
        else {
            changeDateFormate(new Date(new Date().getFullYear() + "-" + (location.state.month + 1) + "-" + location.state.date));
        }
        GetOnboardingData();
        // GetBoard();
        GetGroups();
    }, [])

    if (isLoader == true) {
        return (
            <div id='overlay'>
                <img src={require('../Assets/logo_overlay.png')} className='overlay_logo' />
            </div>
        )
    }

    return (
        <div>
            <div className='row no-gutter mx-0 align-items-center justify-content-between p-2 mt-3'>
                <div className='col-sm-2 head-1'>New Post</div>
                <div className='col-lg-4'>
                </div>

                <div className='col-lg-5'>
                    <div>
                        {
                            progess > 0
                                ?
                                <Progress percent={progess} />
                                :
                                null
                        }
                    </div>
                </div>
            </div>
            <hr />

            <div className='row no-gutter mx-0'>

                <div className='col-sm-8 twit-box1 mb-3'>
                    {/* <div className='row no-gutter mx-0'>
                <div className='col-lg-4 box1-text' >Create Post</div>
                <div className='col-lg-5'></div>
                <div className='col-lg-3 box1-text'>Select All</div>
            </div> */}

                    <div className='d-flex flex-column mt-5'>
                        <button className='d-flex w-100 list_btn justify-content-between align-items-center' onClick={() => { setShowList(!showList) }}>
                            {/* Show Selected Platform list */}
                            <div className='d-flex '>
                                {
                                    SelectedPlatform != null
                                        ?
                                        [...new Set(SelectedPlatform.slice(0, 4))].map((elem) => {
                                            return (
                                                <span className='d-flex platform_name'>
                                                    {
                                                        elem.platform == "FACEBOOK"
                                                            ?
                                                            <i class="fa-brands fa-facebook" style={{ color: '#1877F2' }}></i>
                                                            :
                                                            elem.platform == "INSTAGRAM"
                                                                ?
                                                                <i class="fa-brands fa-instagram" style={{ color: '#FD1D1D' }}></i>
                                                                :
                                                                elem.platform == "PINTEREST"
                                                                    ?
                                                                    <i class="fa-brands fa-pinterest" style={{ color: '#E60023' }}></i>
                                                                    :
                                                                    null
                                                    }
                                                </span>
                                            )
                                        })
                                        :
                                        null
                                }
                            </div>
                            {/* Show Selcted platform length */}
                            <div className='d-flex align-items-center'>
                                <span className='mx-2 more_txt'>
                                    {
                                        SelectedPlatform != null
                                            ?
                                            <>
                                                {
                                                    SelectedPlatform.length <= 4
                                                        ?
                                                        null
                                                        :
                                                        SelectedPlatform.length - 4 + " " + "more..."
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </span>

                                {
                                    showList
                                        ?
                                        <i class="fa fa-angle-up"></i>
                                        :
                                        <i class="fa fa-angle-down"></i>
                                }
                            </div>
                        </button>

                        {/* Show List of Platforms in groups */}
                        {
                            showList
                                ?
                                <div className='List_dropdown'>
                                    <ul>
                                        {
                                            avalibaleProfile != null
                                                ?
                                                ShowProfiles()
                                                :
                                                null
                                        }
                                    </ul>
                                </div>
                                :
                                null
                        }




                        {/* <div>
                            <input type="checkbox" name='Twitter' />
                            <button className='btn' onClick={() => { setPlatform('Twitter'); setPublishType('IMAGE'); }} >
                                {
                                    Paltform == "Twitter"
                                        ?
                                        <img src={require('../Assets/Twitter_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches1.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div>
                            <input type="checkbox" name='Instagram' />
                            <button className='btn' onClick={() => { setPlatform('Instagram'); setPublishType('IMAGE'); }}>
                                {
                                    Paltform == "Instagram"
                                        ?
                                        <img src={require('../Assets/Instagram_Switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches6.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div>
                            <input type="checkbox" name='Facebook' />

                            <button className='btn' onClick={() => { setPlatform('Facebook'); setPublishType('IMAGE'); }}>
                                {
                                    Paltform == "Facebook"
                                        ?
                                        <img src={require('../Assets/Facebook_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches2.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div >
                            <input type="checkbox" name='Linkedin' />
                            <button className='btn' onClick={() => { setPlatform('Linkedin') }}>
                                {
                                    Paltform == "Linkedin"
                                        ?
                                        <img src={require('../Assets/Linkedin_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches3.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div >
                            <input type="checkbox" name='Youtube' />
                            <button className='btn' onClick={() => { setPlatform('Youtube'); setPublishType('REELS') }}>
                                {
                                    Paltform == "Youtube"
                                        ?
                                        <img src={require('../Assets/Youtube_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches4.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div >
                            <input type="checkbox" name='Youtube' />

                            <button className='btn' onClick={() => { setPlatform('GMB'); setPublishType('IMAGE'); }}>
                                {
                                    Paltform == "GMB"
                                        ?
                                        <img src={require('../Assets/GMB_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches5.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div >
                            <input type="checkbox" name='Pinterest' />

                            <button className='btn' onClick={() => { setPlatform('Pinterest'); setPublishType('IMAGE'); }}>
                                {
                                    Paltform == "Pinterest"
                                        ?
                                        <img src={require('../Assets/Pintrest_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switch7.png')} className='Tprofile' />
                                }
                            </button>
                        </div> */}


                        {/* <div className='col-sm-6'></div> */}

                    </div>

                    <div className='d-flex'>
                        {/* different type for platforms */}
                        {/* <div >
                            <button style={{ border: "hidden", background: "#fff", marginTop: "15" }}>
                                {
                                    Paltform == "Twitter"
                                        ?
                                        <img src={require('../Assets/twitter_logo.png')} className='twitter-cross' />
                                        :
                                        Paltform == "Instagram"
                                            ?
                                            <img src={require('../Assets/Insta_logo.png')} className='twitter-cross' />
                                            :
                                            Paltform == "Facebook"
                                                ?
                                                <img src={require('../Assets/FB_logo.png')} onClick={() => { DataStore() }} className='twitter-cross' />
                                                :
                                                Paltform == "Linkedin"
                                                    ?
                                                    <img src={require('../Assets/Linkedin_logo.png')} className='twitter-cross' />
                                                    :
                                                    Paltform == "Youtube"
                                                        ?
                                                        <img src={require('../Assets/Youtube_logo.png')} className='twitter-cross' />
                                                        :
                                                        Paltform == "Pinterest"
                                                            ?
                                                            <img src={require('../Assets/pinterest.png')} className='twitter-cross' />
                                                            :
                                                            <img src={require('../Assets/GMB_logo.png')} className='twitter-cross' />

                                }
                            </button>
                        </div> */}

                        <div className='w-100'>
                            {
                                SelectedPlatform != null
                                    ?
                                    <div className='social_media'>
                                        {
                                            SelectedPlatform.find(o => o.platform == "PINTEREST")
                                                ?
                                                <div className='d-flex align-items-center justify-content-between w-100'>
                                                    <div className='d-flex algin-items-center '>
                                                        <input type="radio" name='post_type' checked={PublishType == "IMAGE" ? true : false} onClick={() => { setPublishType('IMAGE'); setMessage(''); }} />
                                                        <label className='px-2'>Post</label>
                                                    </div>

                                                    <div className='d-flex algin-items-center'>
                                                        <input type="radio" disabled name='post_type' checked={PublishType == "REELS" ? true : false} onClick={() => { setPublishType('REELS'); setMessage(''); }} />
                                                        <label className='px-2'>Reel</label>
                                                    </div>

                                                    <div className='d-flex algin-items-center'>
                                                        <input type="radio" disabled name='post_type' checked={PublishType == "STORIES" ? true : false} onClick={() => { setPublishType('STORIES'); setPostButtonEnable(false); setMessage('Story message Disable') }} />
                                                        <label className='px-2'>
                                                            Story
                                                        </label>
                                                    </div>

                                                    <div className='d-flex algin-items-center'>
                                                        <input type="radio" disabled name='post_type' checked={PublishType == "Multiple" ? true : false} onClick={() => { setPublishType('Multiple'); setPostButtonEnable(false); setMessage('') }} />
                                                        <label className='px-2'>
                                                            Carousel Posting
                                                        </label>
                                                    </div>
                                                </div>
                                                :
                                                <div className='d-flex align-items-center justify-content-between w-100'>
                                                    <div className='d-flex algin-items-center '>
                                                        <input type="radio" name='post_type' checked={PublishType == "IMAGE" ? true : false} onClick={() => { setPublishType('IMAGE'); setMessage(''); }} />
                                                        <label className='px-2'>Post</label>
                                                    </div>

                                                    <div className='d-flex algin-items-center'>
                                                        <input type="radio" name='post_type' checked={PublishType == "REELS" ? true : false} onClick={() => { setPublishType('REELS'); setMessage(''); }} />
                                                        <label className='px-2'>Reel</label>
                                                    </div>

                                                    {
                                                        SelectedPlatform.find(o => o.platform == "FACEBOOK" && "PINTEREST")
                                                            ?
                                                            <div className='d-flex algin-items-center'>
                                                                <input type="radio" disabled name='post_type' checked={PublishType == "STORIES" ? true : false} onClick={() => { setPublishType('STORIES'); setPostButtonEnable(false); setMessage('Story message Disable') }} />
                                                                <label className='px-2'>
                                                                    Story
                                                                </label>
                                                            </div>
                                                            :
                                                            <div className='d-flex algin-items-center'>
                                                                <input type="radio" name='post_type' checked={PublishType == "STORIES" ? true : false} onClick={() => { setPublishType('STORIES'); setPostButtonEnable(false); setMessage('Story message Disable') }} />
                                                                <label className='px-2'>
                                                                    Story
                                                                </label>
                                                            </div>
                                                    }

                                                    {/* <div className='d-flex algin-items-center'>
                                                        <input type="radio" name='post_type' checked={PublishType == "STORIES" ? true : false} onClick={() => { setPublishType('STORIES'); setPostButtonEnable(false); setMessage('Story message Disable') }} />
                                                        <label className='px-2'>
                                                            Story
                                                        </label>
                                                    </div> */}

                                                    <div className='d-flex algin-items-center'>
                                                        <input type="radio" name='post_type' checked={PublishType == "Multiple" ? true : false} onClick={() => { setPublishType('Multiple'); setPostButtonEnable(false); setMessage('') }} />
                                                        <label className='px-2'>
                                                            Carousel Posting
                                                        </label>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    :
                                    null
                            }

                            <div className='box1-small'>
                                <div style={{ display: "flex", flexDirection: "row" }}>

                                    {
                                        captionLoad &&
                                        <Spin />
                                    }

                                    <textarea disabled={PublishType == "STORIES" ? true : false} value={messages} onChange={(e) => { setMessage(e.target.value); }} ref={messageRef} placeholder='Start writing or' style={{ color: "#59626a", fontSize: "12", marginTop: "10", border: 'none', width: '70%', minHeight: '80px', maxHeight: '80px', resize: 'none', marginBottom: '20px' }}>
                                    </textarea>

                                    {/* <div>
                                        <button className='assist-btn' onClick={() => { GenerateCaption() }}>Use the AI Assistant</button>
                                    </div> */}
                                </div>

                                {
                                    PhotoPreivew.length != 0
                                        ?
                                        <div className='row'>
                                            {
                                                PhotoPreivew.map((elem) => {
                                                    return (
                                                        <div className='col-sm-3 image_preview_Section'>
                                                            <button className='cross_photo' onClick={() => { DeleteImage(elem) }}>
                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                            </button>
                                                            <div>
                                                                {
                                                                    fileType == "video"
                                                                        ?
                                                                        <video src={elem} className='preview_imgs'></video>
                                                                        :
                                                                        <img src={elem} className='preview_imgs' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                        :
                                        null
                                }


                                <div className='d-flex align-items-center' style={{ flexBasis: 0 }}>
                                    {
                                        uploadedImg.length == 0
                                            ?
                                            null
                                            :
                                            uploadedImg.map((elem, index) => {
                                                return (
                                                    <div style={{ width: '100px' }}>
                                                        <img src={elem[index]} className='uploaded_imgs' />
                                                    </div>
                                                )
                                            })
                                    }
                                    {/* <FileUploader multiple={true} children={dragNdrop()} handleChange={handelUploaded} name="file" types={fileTypes} /> */}
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Options */}
                    <div style={{ display: "flex", flexDirection: "row", marginTop: '10px', alignItems: "center", marginLeft: '20px' }}>

                        <div>
                            <button className='emoji_btn' onClick={() => { setUploadMenu(!uploadMenu) }}>
                                <img src={require('../Assets/upload_btn.png')} />
                            </button>

                            {
                                uploadMenu
                                    ?
                                    <>
                                        <div className='transprentoverlay' onClick={() => setUploadMenu(false)}></div>
                                        <div className='upload_menu'>
                                            <ul>
                                                {
                                                    PublishType == "IMAGE"
                                                        ?
                                                        <li onClick={() => { PublishType == "IMAGE" || PublishType == "STORIES" || PublishType == "Multiple" ? uploadfile.current.click() : console.log("no Image") }}>
                                                            <i class="fa-solid fa-image mx-1"></i>
                                                            Upload Image
                                                        </li>
                                                        :
                                                        PublishType == "STORIES" || PublishType == "Multiple"
                                                            ?
                                                            <>
                                                                <li onClick={() => { PublishType == "IMAGE" || PublishType == "STORIES" || PublishType == "Multiple" ? uploadfile.current.click() : console.log("no Image") }}>
                                                                    <i class="fa-solid fa-image mx-1"></i>
                                                                    Upload Image
                                                                </li>

                                                                <li onClick={() => { PublishType == "REELS" || PublishType == "STORIES" || PublishType == "Multiple" ? uploadfile.current.click() : console.log("No video") }}>
                                                                    <i class="fa fa-video-camera mx-1" aria-hidden="true"></i>
                                                                    Upload Video
                                                                </li>
                                                            </>
                                                            :
                                                            <li onClick={() => { PublishType == "REELS" || PublishType == "STORIES" || PublishType == "Multiple" ? uploadfile.current.click() : console.log("No video") }}>
                                                                <i class="fa fa-video-camera mx-1" aria-hidden="true"></i>
                                                                Upload Video
                                                            </li>
                                                }
                                            </ul>
                                        </div>
                                    </>
                                    :
                                    null
                            }


                            <input type="file" accept={PublishType == "IMAGE" ? "image/*" : "video/mp4"} style={{ display: 'none' }} ref={uploadfile} onChange={(e) => { uploadStocket(e); setFileUpload(e.target.files[0]); }} />
                        </div>

                        <div>
                            <button className='emoji_btn' onClick={() => { setEmoji(!emoji) }}>
                                <img src={require('../Assets/Icons/emoji.png')} />
                            </button>
                        </div>

                        {
                            emoji
                                ?
                                <div className='emoji_picker'>
                                    <EmojiPicker onEmojiClick={(e) => { AddEmoji(e) }} />
                                </div>
                                :
                                null
                        }

                        <button className='assist-btn' onClick={() => { GenerateCaption() }}>
                                    <img src={require('../Assets/Aicaption.png')} />
                        </button>


                        {/* <div>
                            <button className='emoji_btn'>
                                <i class="fa-solid fa-hashtag"></i>
                            </button>
                        </div> */}

                        {/* <div>
                            <button className='emoji_btn'>
                                <strong>Bold</strong>
                            </button>
                        </div>

                        <div>
                            <button className='emoji_btn'>
                                <i>Italic</i>
                            </button>
                        </div>

                        <div>
                            <button className='emoji_btn'>
                                <u>Underline</u>
                            </button>
                        </div> */}
                    </div>

                    {/* Other Fileds */}
                    {
                        SelectedPlatform != null
                            ?
                            <>
                                {
                                    SelectedPlatform.find(o => o.platform === 'FACEBOOK') && PublishType == "REELS"
                                        ?
                                        <div className='mt-2 option_section'>
                                            <div className='platform_field_names'>Facebook Option</div>
                                            <table className='w-100 mt-2 table options_table'>
                                                <tr>
                                                    <td>
                                                        <label>
                                                            Title
                                                        </label>
                                                    </td>

                                                    <td>
                                                        <div className='w-100'>
                                                            <input type="text" className='w-100' onChange={(e) => { setTitle(e.target.value) }} />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <label>
                                                            Descriptions
                                                        </label>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <textarea className='w-100' onChange={(e) => { setDesc(e.target.value) }}>
                                                            </textarea>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    SelectedPlatform.find(o => o.platform === 'PINTEREST') && PublishType == "IMAGE"
                                        ?
                                        <div className='mt-2 option_section'>
                                            <div className='platform_field_names'>Pinterest Option</div>

                                            <div>
                                                <label className='pint-label'>Pin Title</label>
                                                <div><input className='pint-input' placeholder='Pin Title' /></div>
                                            </div>

                                            <div style={{ marginTop: 15 }}>
                                                <label className='pint-label'>Pin To</label>
                                                <div>
                                                    <input className='pint-input' placeholder='Choose Board(Required)' value={BoardID != null ? BoardID.name : null} onClick={() => { setBoardList(!boardList) }} />
                                                </div>

                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    {
                                                        boardList
                                                            ?
                                                            <div className='pint_board_list_menu'>
                                                                <ul className='pint_board_list'>
                                                                    <li onClick={() => { setopenCreateboard(true) }}>
                                                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                                                        <span className='mx-3'>
                                                                            Create New board
                                                                        </span>
                                                                    </li>
                                                                    {
                                                                        PintBoardList
                                                                            ?
                                                                            PintBoardList.map((elem) => {
                                                                                console.log(elem)
                                                                                return (
                                                                                    <li onClick={() => { setBoardID(elem); setBoardList(false) }}>
                                                                                        <img src={elem.media.image_cover_url} className='board_list_img' />
                                                                                        <span className='mx-2'>
                                                                                            {elem.name}
                                                                                        </span>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                            :
                                                                            null
                                                                    }
                                                                    <li></li>
                                                                </ul>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>

                                            <div style={{ marginTop: 15 }}>
                                                <label className='pint-label'>Destination URL</label>
                                                <div><input className='pint-input' placeholder='Destination URL' value={PhotoPreivew[0]} /></div>
                                            </div>

                                        </div>
                                        :
                                        null
                                }
                            </>
                            :
                            null
                    }
                    <div style={{ marginTop: "35px", marginBottom: '20px' }}>
                        <hr />
                        <div className='d-flex'>
                            <div className='col-sm-5 date'>Schedule Date: <b>
                                {
                                    scheduleDates
                                        ?
                                        MonthName[(new Date(scheduleDates).getMonth()).toLocaleString('default', { month: 'short' })] + " " + (new Date(scheduleDates).getDate()) + ", " + (new Date(scheduleDates)).getHours() + ":" + (new Date(scheduleDates)).getMinutes()
                                        :
                                        null

                                }
                            </b>
                                <button className='edit-btn' onClick={() => { setDatePickerToggle(true) }}>Edit</button>
                                {/* <input type="date"ref={scheduleDateRef}/> */}
                                <DatePicker
                                    open={datepickertoggle}
                                    format='YYYY-MM-DD HH:mm:ss'
                                    onChange={(e) => {
                                        changeDateFormate(e.$d)
                                        // setScheduleDate(e.$d)
                                    }}
                                    onOk={() => { setDatePickerToggle(false) }}
                                    style={{ visibility: 'hidden' }}
                                    showTime={true}
                                />
                            </div>

                            <div className='col-sm-3'></div>

                            <div className='col-sm-2 '>
                                <button className='draft-btn-enable'>Schedule Draft</button>
                            </div>

                            <div className='col-sm-2'>
                                {
                                    PublishType == "STORIES"
                                        ?
                                        <button className={postBtnEnable == true ? 'draft-btn' : 'draft-btn-enable'} onClick={() => { Post() }}>
                                            {
                                                APIwait == false
                                                    ?
                                                    <>
                                                        {
                                                            new Date(scheduleDates) > new Date()
                                                                ?
                                                                "Schedule Post"
                                                                :
                                                                "Post"
                                                        }
                                                    </>
                                                    :
                                                    <Spin indicator={antIcon} />

                                            }

                                        </button>
                                        :
                                        <button className={postBtnEnable == true && messages == '' ? 'draft-btn' : 'draft-btn-enable'} disabled={postBtnEnable == true && messages == '' || PublishType == "STORIES" ? true : false} onClick={() => { Post() }}>
                                            {
                                                APIwait == false
                                                    ?
                                                    <>
                                                        {
                                                            new Date(scheduleDates) > new Date()
                                                                ?
                                                                "Schedule Post"
                                                                :
                                                                "Post"
                                                        }
                                                    </>
                                                    :
                                                    <Spin indicator={antIcon} />

                                            }

                                        </button>
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <div className='col-sm-4 twit-box2'>
                    <div className='box2-head d-flex flex-column justify-content-between'>
                        <div className='head-1'>Preview</div>
                        <div className='mt-3'>
                            {/* <div className='mx-3'>
                                {
                                    Fileupload && PublishType == "IMAGE"
                                        ?
                                        <img src={PhotoPreivew} style={{ width: "100%", borderRadius: '10px' }} />
                                        :
                                        PublishType == "REELS"
                                            ?
                                            <video src={sessionStorage.getItem("uploadImage")}>
                                            </video>
                                            :
                                            null
                                }
                            </div> */}

                            {
                                SelectedPlatform != null
                                    ?
                                    <div className='d-flex align-items-center flex-column'>
                                        {
                                            SelectedPlatform.find(o => o.platform === 'FACEBOOK')
                                                ?
                                                <FacebookModal Previewimages={PhotoPreivew} PostType={fileType} Previewcaptions={messages} />
                                                :
                                                null
                                        }

                                        {
                                            SelectedPlatform.find(o => o.platform === 'INSTAGRAM')
                                                ?
                                                <InstagarmModal Previewimages={PhotoPreivew} PostType={fileType} Previewcaptions={messages} />
                                                :
                                                null
                                        }

                                        {
                                            SelectedPlatform.find(o => o.platform === "PINTEREST")
                                                ?
                                                <Pinerest Previewimages={PhotoPreivew} PostType={fileType} Previewcaptions={messages} />
                                                :
                                                null
                                        }
                                        {/* {
                                            Paltform == "Youtube"
                                                ?
                                                <YoutubeModal Previewimages={PhotoPreivew} PostType={fileType} Previewcaptions={messages} title={title} />
                                                :
                                                null
                                        } */}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </div>

                    <div>
                        {/* <img src={require('../Assets/Twitter-box2.png')} className='Twit-box2Img' /> */}
                    </div>
                </div>

            </div>


            {/* Create Board Modal */}
            <Modal
                open={openCreateboard}
                footer={null}
                onCancel={() => setopenCreateboard(false)}
                title="Creat New Board"
            >
                <div>
                    <div>
                        <table className='table'>
                            <tr>
                                <td>
                                    <label>
                                        Name of Board
                                    </label>
                                </td>

                                <td>
                                    <input type="text" onChange={(e) => { setNewBoardName(e.target.value) }} className='pint-input' />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>
                                        Descriptions (Optional)
                                    </label>
                                </td>

                                <td>
                                    <textarea className='pint-input' onChange={(e) => { setNewBoardDesc(e.target.value) }}></textarea>
                                </td>
                            </tr>
                        </table>

                        <div>
                            <button className='create_board_btn' onClick={() => { CreateBoard(); }}>
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
