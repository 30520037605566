import React, { useEffect, useState } from 'react'
import Logo from '../Assets/Logo2.png'
import Dash from '../Assets/Dash_icon.png'
import inbox from '../Assets/inbox.png'
import publishing from '../Assets/publishing.png'
import reports from '../Assets/reports.png'
import Assets from '../Assets/Cloud.png'
import Profile from '../Assets/profile.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { StoreSideBar } from './store/Slices/SIdeSlice'
import { sidebarstore } from './action'
import axios from 'axios'
import { Modal, message } from 'antd'
import ConnectModal from './ConnectModal'


export default function Sidebar() {
  const selector = useSelector((state) => {
    return state.SideBarName[0]
  });

  const ProfileSelector = useSelector((state) => {
    return state.Profiles
  })
  const [active, setActive] = useState('');
  const location = useLocation();
  const [Showgrp, setShowgrp] = useState(false);
  const [groupsList, setGroupList] = useState(null);
  const [isModalOpen,setIsModalOpen]=useState(false);

  const dispatch = useDispatch();

  const [showsubmenu, setShowsubmenu] = useState(false);

  const navigate = useNavigate();

  function Log_out() {
    if (localStorage.getItem("Email")) {
      localStorage.removeItem("Email");
      localStorage.removeItem('user_Token');
      window.location.reload('/')
    }
    else {
      sessionStorage.removeItem("Email");
      sessionStorage.removeItem('user_Token');
      window.location.reload('/')
    }
  }

  const GetGroups = () => {
    var token;

    if (localStorage.getItem("user_Token")) {
      token = localStorage.getItem("user_Token");
    }
    else {
      token = sessionStorage.getItem("user_Token")
    }

    axios.get(`${process.env.REACT_APP_BACKEND_API}/group/get`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => {
        setGroupList(res.data.groups)

        if (localStorage.getItem('oolook_default_group') == undefined) {
          localStorage.setItem('oolook_default_group', res.data.groups[0].name);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const DefaultGrpset = (groupName) => {
    localStorage.setItem('oolook_default_group', groupName);
    setShowgrp(false);
  }

  useEffect(() => {
    GetGroups()
  }, [])

  return (
    <>
      <div className='sidebar'>
        <div className="logodiv">
          <img className="sidelogo" src={Logo} />
        </div>

        <div>
          <button className='w-100 group_btn' onClick={() => { setShowgrp(!Showgrp); }}>
            <i class="fa fa-folder fa-xl" aria-hidden="true"></i>
          </button>
        </div>

        <ul className='sidenav_list'>
          <Link to="/Dashboard" onClick={() => { dispatch(StoreSideBar('/')); setShowsubmenu(false) }} className={selector == '/' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={Dash} />
          </Link>

          <Link onClick={() => { dispatch(StoreSideBar('Inbox')); setShowsubmenu(true) }} className={selector == 'Inbox' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={inbox} />
          </Link>

          <Link onClick={() => { dispatch(StoreSideBar('Reports')); setShowsubmenu(true) }} className={selector == 'Reports' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={reports} />
          </Link>


          <Link onClick={() => { dispatch(StoreSideBar('Publishing')); setShowsubmenu(true) }} className={selector == "Publishing" || selector == "Approval" || selector == "Rejected" ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={publishing} />
          </Link>

          <Link onClick={() => { dispatch(StoreSideBar('Library')); setShowsubmenu(true) }} className={selector == 'Library' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={Assets} />
          </Link>

          <Link onClick={() => { setIsModalOpen(true); setShowsubmenu(false) }} className={selector == 'Profile' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={Profile} />
          </Link>
        </ul>

        {/* Balance section */}
        <div className='bottom-section'>
          {/* Account Setting */}
          <Link onClick={() => { dispatch(StoreSideBar('Account & Setting')); setShowsubmenu(true) }} className={selector == 'Profile' ? 'account_setting links active d-flex' : 'account_setting links d-flex'}>
            <img src={require('../Assets/Setting.png')} className='avtar' />
          </Link>
        </div>

      </div>

      {
        showsubmenu
          ?
          <>
            <div id='overlaysidebar' onClick={() => { setShowsubmenu(false) }}></div>
            <div className='submenu'>

              <div className='d-flex flex-column sub_menu_header'>
                <label className='user_name'>
                  {
                    localStorage.getItem("oolook_default_group")
                      ?
                      localStorage.getItem("oolook_default_group")
                      :
                      null
                  }
                </label>

                <label className='page_name'>
                  {selector}
                </label>
              </div>

              {/* Message Sub menu */}
              <ul className={selector == "Inbox" ? 'sub_menu_show' : 'sub_menu'}>
                <Link to="Inbox" onClick={() => setShowsubmenu(false)}>
                  <li className={selector == "Inbox" ? 'active' : ''}>All Message</li>
                </Link>

                <Link to="Message" onClick={() => setShowsubmenu(false)}>
                  <li className={selector == "Message" ? 'active' : ''}>Completed</li>
                </Link>
              </ul>

              {/* Report sub menu */}
              <ul className={selector == "Reports" ? 'sub_menu_show' : 'sub_menu'}>
                <Link to="/Dashboard/Reports" onClick={() => { setShowsubmenu(false); dispatch(StoreSideBar('Reports')); }}>
                  <li className={location.pathname == "/Reports" ? 'active' : ''}>Profile Reports</li>
                </Link>

                <Link to="Postreports" onClick={() => setShowsubmenu(false)}>
                  <li className={location.pathname == "/Postreports" ? 'active' : ''}>Post Reports</li>
                </Link>

                <Link to="Competitor" onClick={() => setShowsubmenu(false)}>
                  <li className={location.pathname == "/Reports/Competitor" ? 'active' : ''}>Competitor Reports</li>
                </Link>
              </ul>

              {/* Publish sub menu */}
              <ul className={selector == "Publishing" || selector == "Approval" || selector == "Rejected" ? 'sub_menu_show' : 'sub_menu'}>
                <Link to="Publishing" onClick={() => { dispatch(StoreSideBar('Publishing')); setShowsubmenu(false) }}>
                  <li className={selector == "Publishing" ? 'active' : ''}>Calender</li>
                </Link>

                <Link to="Approval" onClick={() => { dispatch(StoreSideBar('Approval')); setShowsubmenu(false) }}>
                  <li className={selector == "Approval" ? 'active' : ''}>Need Approval</li>
                </Link>

                <Link to="Rejected" onClick={() => { dispatch(StoreSideBar('Rejected')); setShowsubmenu(false) }}>
                  <li className={selector == "Rejected" ? 'active' : ''}>Rejected</li>
                </Link>
              </ul>

              {/* Assets sub menu */}
              <ul className={selector == "Library" ? 'sub_menu_show' : 'sub_menu'}>
                <Link to="Businessprofile" onClick={() => { dispatch(StoreSideBar('Library')); setShowsubmenu(false) }}>
                  <li className={selector == "Assets" ? 'submenu_active' : ''}>
                    Business Profile
                  </li>
                </Link>

                <Link to="Branding" onClick={() => { dispatch(StoreSideBar('Library')); setShowsubmenu(false) }}>
                  <li className={selector == "Recents" ? 'submenu_active' : ''}>Branding</li>
                </Link>

                <Link to="Topics" onClick={() => { dispatch(StoreSideBar('Library')); setShowsubmenu(false) }}>
                  <li className={selector == "Starred" ? 'submenu_active' : ''}>Topics</li>
                </Link>

                <Link to="Assets" onClick={() => { dispatch(StoreSideBar('Library')); setShowsubmenu(false) }}>
                  <li className={selector == "Bin" ? 'submenu_active' : ''}>Media</li>
                </Link>

                <Link to="Rejected" onClick={() => { dispatch(StoreSideBar('Library')); setShowsubmenu(false) }}>
                  <li className={selector == "Bin" ? 'submenu_active' : ''}>Profiles</li>
                </Link>
              </ul>

              <ul className={selector == "Account & Setting" ? 'sub_menu_show' : 'sub_menu'}>
                <Link to="AccountSetting" onClick={() => { dispatch(StoreSideBar('Account & Setting')); setShowsubmenu(false) }}>
                  <li className={selector == "Account & Billing" ? 'submenu_active' : ''}>
                    Account & Billing
                  </li>
                </Link>

                <Link to="GroupsProfile" onClick={() => { dispatch(StoreSideBar('GroupsProfile')); setShowsubmenu(false) }}>
                  <li className={selector == "Groups & Social Profiles" ? 'submenu_active' : ''}>Groups & Social Profiles</li>
                </Link>

                <Link to="https://oolook.in/privacy-policy-2/">
                  <li>Privacy & Policy</li>
                </Link>

                <Link onClick={() => { Log_out() }}>
                  <li className='submenu_active'>
                    Log Out
                  </li>
                </Link>
              </ul>
            </div>
          </>
          :
          null
      }

      {
        Showgrp
          ?
          <div className='group_sideModal'>
            <div className='p-3 group_modal_head'>
              <h4> Select Business Profile</h4>

              <div>
                <Link className='grp_modal_add_btn' to="/Dashboard/GroupsProfile" onClick={() => { setShowgrp(false) }}>
                  Add New Business Profile
                </Link>
              </div>
            </div>

            <div className='px-4 py-3'>
              <span>
                {
                  localStorage.getItem("oolook_default_group")
                    ?
                    localStorage.getItem("oolook_default_group")
                    :
                    null
                }
              </span>
            </div>

            <div>
              <ul className='group_list'>
                {
                  groupsList != null
                    ?
                    groupsList.map((elem) => {
                      return (
                        <li>
                          <button onClick={() => { DefaultGrpset(elem.name) }} className={localStorage.getItem('oolook_default_group') === (elem.name) ? 'group_list_active' : ''}>
                            {elem.name}
                          </button>
                        </li>
                      )
                    })
                    :
                    null
                }
              </ul>
            </div>
          </div>
          :
          null
      }

      <Modal title="Connect a Profile" open={isModalOpen} footer={null} onCancel={() => { setIsModalOpen(false);}}>
        <ConnectModal/>
      </Modal>
    </>
  )
}
