import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Cookies from 'js-cookie';
import { Spin } from 'antd';
import { message } from 'antd';
import { EngagmentGrowth } from '../store/Slices/EngagementSlice';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


export default function Engagementchart() {
    const [InstaImpression, setInstaImpression] = useState([]);
    const [isLoader, setIsloader] = useState(true);
    const [label, setLabel] = useState([]);
    const [FbImpressrions, setFbImpressions] = useState([]);
    const [LinkedinEng, setLinkedinEng] = useState([]);
    const [TwitterEng, setTwitterEng] = useState([]);
    const [PintrestEng, setPintrestEng] = useState([]);

    const [AudienceGrowths, setAudienceGrowths] = useState(null);
    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    const Filter = useSelector((state) => {
        return state.Filter
    })

    const dispatch = useDispatch()


    const FBEngeProcess = (newValues) => {
        const FBRAWData = []
        for (var i = 0; i < newValues.length; i++) {
            FBRAWData.push(...newValues[i]);
        }

        const FbnewObj = Object.values(FBRAWData.reduce((a, { end_time, value }) => {
            a[end_time] = (a[end_time] || { end_time, value: 0 });
            a[end_time].value = parseInt(String(Number(a[end_time].value) + Number(value)));
            return a;
        }, {}));

        FbImpressrions.push({
            x: (new Date(Sinceselector[0] * 1000).getMonth() + 1).toString().padStart(2, "0") + "-" + new Date(Sinceselector[0] * 1000).getDate().toString().padStart(2, "0"),
            y: 0
        });

        FbnewObj.map((instaObj) => {
            FbImpressrions.push({
                x: (instaObj.end_time).split("T")[0].split("-")[1] + "-" + (instaObj.end_time).split("T")[0].split("-")[2],
                y: instaObj.value
            })
        })
    }

    // Pinterest Data Process
    const PinEngagementProcess = (PinEngagement) => {
        let PinEngObj = [];

        // make raw response date into formate data
        PinEngagement.map((element) => {
            if (element.data_status == "READY") {
                PinEngObj.push({
                    end_time: element.date,
                    value: element.metrics.ENGAGEMENT
                })
            }
            else {
                PinEngObj.push({
                    end_time: element.date,
                    value: 0
                })
            }
        })


        const PinnewObj = Object.values(PinEngObj.reduce((a, { end_time, value }) => {
            a[end_time] = (a[end_time] || { end_time, value: 0 });
            a[end_time].value = parseInt(String(Number(a[end_time].value) + Number(value)));
            return a;
        }, {}));


        PinnewObj.map((instaObj) => {
            PintrestEng.push({
                x: (instaObj.end_time).split("-")[1] + "-" + (instaObj.end_time).split("-")[2],
                y: instaObj.value
            })
        })
    }


    const GetEngagement = () => {
        PintrestEng.length = 0;
        const FilterProfile = [];
        FbImpressrions.length = 0;
        setIsloader(true);
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        let FBEngagement = [];

        let PinEngagement = [];

        Filter[0].map((elem) => {
            if (elem.platform == "FACEBOOK") {
                FilterProfile.push(elem.platform_id)
            }
            else {
                FilterProfile.push(elem.platform_id)
            }
        })

        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/group-analytics`, {
            groupName: localStorage.getItem('oolook_default_group'),
            selectedProfiles: FilterProfile,
            since: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            until: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
        }, {
            headers: {
                'Authorization': `Barear ${token}`
            }
        })
            .then((res) => {
                if (res.data.analytics.FACEBOOK.length != 0) {
                    (res.data.analytics.FACEBOOK).map((elem) => {
                        (elem.data).map((FBelem) => {
                            if (FBelem.name == "page_post_engagements") {
                                FBEngagement.push(FBelem.values)
                            }
                        })
                    })
                }

                if (res.data.analytics.PINTEREST.length != 0) {
                    (res.data.analytics.PINTEREST).map((elem) => {
                        (elem.analytics.all.daily_metrics).map((datas) => {
                            PinEngagement.push(datas);
                        })
                    })
                }

                if (res.data.analytics.INSTAGRAM.length != 0) {
                    (res.data.analytics.INSTAGRAM).map((elem) => {
                        // console.log(elem)
                    })
                }

                if (FBEngagement.length != 0) {
                    FBEngeProcess(FBEngagement);
                }

                if (PinEngagement.length != 0) {
                    PinEngagementProcess(PinEngagement)
                }

                const EngagementGrow = data.datasets[1].data.reduce((accumulator, currentValue) =>
                    accumulator + currentValue.y, 0
                );

                dispatch(EngagmentGrowth(EngagementGrow))

                setIsloader(false);

            })
            .catch((err) => {
                console.log(err)
            })
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                }
            },
        },
        elements: {
            point: {
                radius: 0.5
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 30
                }
            }
        }
    };

    const data = {
        datasets: [
            {
                label: 'Instagram', // Name the series
                data: InstaImpression, // Specify the data values array
                fill: true,
                borderColor: '#CE3665', // Add custom color border (Line)
                backgroundColor: '#CE3665', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.4
            },
            {
                label: 'Facebook', // Name the series
                data: FbImpressrions, // Specify the data values array
                fill: true,
                borderColor: '#6F5ED3', // Add custom color border (Line)
                backgroundColor: '#6F5ED3', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.3

            },
            // {
            //     label: 'Linkedin', // Name the series
            //     data: LinkedinEng, // Specify the data values array
            //     fill: true,
            //     borderColor: '#FFCD1C', // Add custom color border (Line)
            //     backgroundColor: '#FFCD1C', // Add custom color background (Points and Fill)
            //     borderWidth: 0.4, // Specify bar border width
            //     tension: 0.3
            // },
            // {
            //     label: 'Twitter', // Name the series
            //     data: TwitterEng, // Specify the data values array
            //     fill: true,
            //     borderColor: '#08C4B2', // Add custom color border (Line)
            //     backgroundColor: '#08C4B2', // Add custom color background (Points and Fill)
            //     borderWidth: 1, // Specify bar border width
            //     tension: 0.3
            // },
            {
                label: 'Pintrest', // Name the series
                data: PintrestEng, // Specify the data values array
                fill: true,
                borderColor: '#3896E3', // Add custom color border (Line)
                backgroundColor: '#3896E3', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.3
            }
        ],
    };

    useEffect(() => {
        if (Filter.length != 0) {
            GetEngagement()
        }
    }, [Sinceselector, untilSelector, Filter])

    return (
        <>
            <div className='chart_section'>
                <div className='chart_heading d-flex flex-column px-3 py-3'>
                    <label className='label_head'>
                        Engagement
                    </label>

                    <label className='label_sub_head'>
                        See how people are engaging with your posts during the reporting period.
                    </label>
                </div>

                <div style={{ height: '40vh' }}>
                    {
                        isLoader
                            ?
                            <div className='d-flex align-items-center justify-content-center'>
                                <Spin />
                            </div>
                            :
                            <Line options={options} data={data} />
                    }
                </div>

                <div className='w-100 px-3'>
                    <table className='w-100 table chart_table'>
                        <tr>
                            <th>
                                Engagement Metrics
                            </th>

                            <th>
                                Totals
                            </th>

                            <th>
                                % Change
                            </th>
                        </tr>

                        <tbody>
                            <tr>
                                <td>
                                    Total Engagement
                                </td>

                                <td>
                                    {

                                        isLoader
                                            ?
                                            // data.datasets[0].data.reduce((accumulator, currentValue) =>
                                            //     accumulator + currentValue.y, 0
                                            // ) +
                                            // data.datasets[1].data.reduce((accumulator, currentValue) =>
                                            //     accumulator + currentValue.y, 0
                                            // ) +
                                            // data.datasets[2].data.reduce((accumulator, currentValue) =>
                                            //     accumulator + currentValue.y, 0
                                            // )

                                            data.datasets[1].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            )
                                            :
                                            0
                                    }
                                </td>

                                <td>
                                    2.1%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Total Net Audience Growth
                                </td>

                                <td>

                                </td>

                                <td>
                                    130%
                                </td>
                            </tr>

                            {
                                InstaImpression.length != 0
                                    ?
                                    <tr>
                                        <td>
                                            Instagram Engagement
                                        </td>
                                        <td>
                                            {
                                                InstaImpression.length != 0
                                                    ?
                                                    data.datasets[0].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }

                            {
                                FbImpressrions.length != 0
                                    ?
                                    <tr>
                                        <td>
                                            Facebook Engagement
                                        </td>
                                        <td>
                                            {
                                                FbImpressrions.length != 0
                                                    ?
                                                    data.datasets[1].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }

                            {
                                PintrestEng.length != null
                                    ?
                                    <tr>
                                        <td>
                                            Pintrest Engagement
                                        </td>
                                        <td>
                                            {
                                                PintrestEng.length != 0
                                                    ?
                                                    data.datasets[2].data.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.y, 0
                                                    )
                                                    :
                                                    null
                                            }
                                        </td>

                                        <td>
                                            2400%
                                        </td>
                                    </tr>
                                    :
                                    null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
