import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Countrychange } from '../Components/store/Slices/CountrySlice';
import { TimezoneChange } from '../Components/store/Slices/TimezoneSlice';

export default function TimezoneDropdown({ Timezonelist }) {

    const [listShow, setListShow] = useState(false);
    const [ListData, setListData] = useState(Timezonelist);
    const [search, setSearch] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');

    const selector=useSelector((state)=>{return state});

    const dispatch = useDispatch();

    return (
        <div className='w-100'>
            <button onClick={() => setListShow(!listShow)} className='d-flex justify-content-between align-items-center w-100 form_input'>
                <div>
                    {selector.Timezone[0]}
                </div>

                <div className='downarrow_icon'>
                    <i class="fa fa-angle-down fa-xs"></i>
                </div>
            </button>

            {
                listShow
                    ?
                    <div className='drop_list'>
                        <ul>
                            {
                                ListData.map((elem) => {
                                    return (
                                        <li>
                                            <button onClick={() => { setSelectedCountry(elem.city + " " + elem.name); setListShow(false); setListData(Timezonelist); dispatch(TimezoneChange(elem.city + " " + elem.name)) }}>
                                                {elem.city} {elem.name}
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    :
                    null
            }


        </div>
    )
}
