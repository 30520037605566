import { createSlice } from "@reduxjs/toolkit";

const EngagmentSlice=createSlice({
    name:"EngagmentSlice",
    initialState:[],
    reducers:{
        EngagmentGrowth(state,action){  
            state.length=0          
            state.push(action.payload)
            // state=action.payload
            // return
        },
    }
})

export {EngagmentSlice}

export const {EngagmentGrowth}=EngagmentSlice.actions