import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

export default function Activity() {

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    });

    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    })

    const [Engagement, setEngagement] = useState(null);
    const [Impression, setImpression] = useState(null);
    const [previousEng, setPreviousEng] = useState(null);
    const [prevImpression, setPrevImpression] = useState(null);

    const FBProcess = (FBImpression) => {
        let FBImpressions = [];
        if (FBImpression.length > 1) {
            FBImpressions = FBImpression[0].concat(FBImpression[1]);
        }
        else {
            FBImpressions = FBImpressions
        }

        return (FBImpressions.reduce((acc, item) => acc + item.value, 0))
    }

    const GetPofileData = async () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);

        await axios.post(`${process.env.REACT_APP_BACKEND_API}/group/group-analytics`, {
            groupName: localStorage.getItem('oolook_default_group'),
            selectedProfiles: [],
            since: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            until: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(async (res) => {
                // console.log(token)
                var FBImp = [];
                var InstaImp = [];
                // Current Engagement
                if (res.data.analytics.FACEBOOK.length != 0) {
                    (res.data.analytics.FACEBOOK).map((elem) => {
                        (elem.data).map((FBelem) => {
                            if (FBelem.name == "page_post_engagements") {
                                setEngagement((FBelem.values).reduce((acc, item) => acc + item.value, 0))
                            }
                        })
                    })
                }

                // Previous Instagram Impression
                if (res.data.analytics.INSTAGRAM != undefined) {
                    (res.data.analytics.INSTAGRAM).map((elem) => {
                        (elem.data).map((datas) => {
                            if (datas.name == "impressions") {
                                InstaImp.push((datas.values))
                            }
                        })
                    })
                }

                // sort data from response data facebook Previous Impression
                if (res.data.analytics.FACEBOOK != undefined) {
                    (res.data.analytics.FACEBOOK).map((elem) => {
                        (elem.data).map((datas) => {
                            if (datas.name == "page_impressions") {
                                FBImp.push(datas.values)
                                // FacebookImpression.push(datas.values);
                                // console.log(datas.values.reduce((acc, item) => acc + item.value, 0))
                                // setImpression(Impression+datas.values.reduce((acc, item) => acc + item.value, 0))
                            }
                        })
                    })
                }

                const FBim = await FBProcess(FBImp);
                const Instaim = await FBProcess(InstaImp);

                setImpression(FBim + Instaim)

                // console.log(Impression)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const previousMonthData = async () => {
        var token;

        var FBImp = [];
        var InstaImp = [];

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);

        await axios.post(`${process.env.REACT_APP_BACKEND_API}/group/group-analytics`, {
            groupName: localStorage.getItem('oolook_default_group'),
            selectedProfiles: [],
            since: since.getFullYear() + "-" + (since.getMonth()).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            until: until.getFullYear() + "-" + (until.getMonth()).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(async (res) => {
                // console.log(token)


                // Facebook Engagement
                if (res.data.analytics.FACEBOOK.length != 0) {
                    (res.data.analytics.FACEBOOK).map((elem) => {
                        (elem.data).map((FBelem) => {
                            if (FBelem.name == "page_post_engagements") {
                                setPreviousEng((FBelem.values).reduce((acc, item) => acc + item.value, 0))
                            }

                        })
                    })
                }

                // Previous Instagram Impression
                if (res.data.analytics.INSTAGRAM != undefined) {
                    (res.data.analytics.INSTAGRAM).map((elem) => {
                        (elem.data).map((datas) => {
                            if (datas.name == "impressions") {
                                InstaImp.push((datas.values))
                            }
                        })
                    })
                }

                // sort data from response data facebook Previous Impression
                if (res.data.analytics.FACEBOOK != undefined) {
                    (res.data.analytics.FACEBOOK).map((elem) => {
                        (elem.data).map((datas) => {
                            if (datas.name == "page_impressions") {
                                FBImp.push(datas.values)
                            }
                        })
                    })
                }

                const FBim = await FBProcess(FBImp);
                const Instaim = await FBProcess(InstaImp);

                setPrevImpression(FBim + Instaim);

                console.log(prevImpression)

            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        GetPofileData();
        previousMonthData();
    }, [untilSelector, Sinceselector])

    return (
        <div className='row activity'>
            <h4>Your Latest Activity</h4>

            <div className='col-4 px-0 performance_section'>
                <div className='titles'>
                    <label>Performance Summary</label>
                </div>

                <div className='titles'>
                    <div className='row title_box1'>
                        <div className='col-6'>
                            <label>Impressions</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    {Impression}
                                </label>
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                <label style={{ color: "#21A500" }}>
                                    {
                                        Impression != null && prevImpression != null
                                            ?
                                            <>
                                                {
                                                    prevImpression === 0 ? Impression === 0
                                                        ? 0 // No change if both are 0
                                                        : "0"
                                                        : (((Impression - prevImpression) / prevImpression) * 100).toFixed(2)
                                                }
                                            </>
                                            :
                                            null
                                    }
                                    %
                                </label>
                            </div>
                        </div>

                        <div className='col-6'>
                            <label>Engagements</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    {Engagement}
                                </label>
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                <label style={{ color: "#21A500" }}>
                                    {
                                        Engagement != null && previousEng != null
                                            ?
                                            <>
                                                {
                                                    previousEng === 0 ? Engagement === 0
                                                        ? 0 // No change if both are 0
                                                        : "0"
                                                        : ((Engagement - previousEng) / previousEng) * 100
                                                }
                                            </>
                                            :
                                            null
                                    }
                                    %
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='titles'>
                    <div className='row title_box1'>
                        <div className='col-6'>
                            <label>Post Link Clicks</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    0
                                </label>
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                <label>
                                    0%
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='titles'>
                    <div className='row title_box1'>
                        <div className='col-6'>
                            <label>Engagement rate</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    0
                                </label>
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                <label>
                                    0%
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='col-8'>
                <div className='summary'>
                    <div className='d-flex summary_header'>
                        <h4>Campaigns Summary</h4>

                        <div className='summary_inner_box'>
                            <button className='btn summary_create'>
                                Create
                            </button>

                            <label>Sort by</label>

                            <select>
                                <option>Highest Performance</option>
                                <option>Lowest Performance</option>
                            </select>
                        </div>
                    </div>

                    <div className='table_section'>
                        <table className='summary_table'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Campaign Name</th>
                                    <th>Type</th>
                                    <th>Budget</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
