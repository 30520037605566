import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'


export default function GenereatedData({ date, genratedData, month, dataObject, PostedData, importedPosts }) {

    const navigatie = useNavigate();


    if (date == dataObject.format("D")) {
        if (parseInt(dataObject.format("M")) != new Date().getMonth() + 1) {
            return (
                <th key={date} className="weeks-day">
                    <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                        {date}

                        <div className='mt-2 mx-2 d-flex flex-column'>
                            {Object.keys(PostedData).map(platform => (
                                <span className='posts_icons' key={platform}>
                                    {
                                        platform == "FACEBOOK"
                                            ?
                                            <i class="fa-brands fa-facebook"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "INSTAGRAM"
                                            ?
                                            <i class="fa-brands fa-instagram"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "PINTEREST"
                                            ?
                                            <i class="fa-brands fa-pinterest"></i>
                                            :
                                            null
                                    }

                                    {PostedData[platform].length}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* task */}
                    <div style={{ height: "90%" }}>
                        {
                            Object.entries(importedPosts).map((platform) => {
                                return (
                                    <span className='posts_icons' key={platform}>
                                        {
                                            platform[0] == "FACEBOOK"
                                                ?
                                                <>
                                                    <i class="fa-brands fa-facebook"></i>
                                                    {platform[1]}
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            platform[0] == "INSTAGRAM"
                                                ?
                                                <>
                                                    <i class="fa-brands fa-instagram"></i>
                                                    {platform[1]}
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            platform[0] == "PINTEREST"
                                                ?
                                                <>
                                                    <i class="fa-brands fa-pinterest"></i>
                                                    {platform[1]}
                                                </>
                                                :
                                                null
                                        }
                                    </span>
                                )
                            })
                        }

                        {
                            genratedData.map((elem) => {
                                if ((elem.time).split("T")[0].split("-")[2] == date) {
                                    if (((elem.time).split("T")[0].split("-")[1]).replace(/^0+/, '') == month) {
                                        return (
                                            <span className='tasks' onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: elem } }) }}>
                                                <label>
                                                    {
                                                        elem.platform == "Instagram"
                                                            ?
                                                            <i class="fa-brands fa-instagram"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "Facebook"
                                                            ?
                                                            <i class="fa-brands fa-facebook"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "GMB"
                                                            ?
                                                            <i class="fa-brands fa-Google"></i>
                                                            :
                                                            null
                                                    }
                                                </label>
                                                <label>
                                                    1
                                                </label>
                                            </span>
                                        )
                                    }
                                }
                            })
                        }

                        <div className='action_task'>
                            <button id={date} className='tasks add_task' onClick={(e) => navigatie('/Dashboard/PublishPost', { state: { date: parseInt(e.target.id), month: month } })}>
                                {/* <i class="fa fa-plus" aria-hidden="true"></i> */}
                                +
                            </button>

                            <span className='tasks add_task'>
                                <i class='fa fa-edit'></i>
                            </span>
                        </div>
                    </div>
                </th>
            )
        }
        else {
            return (
                <th key={date} className="weeks-day current">
                    <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                        {date}

                        <div className='mt-2 mx-2 d-flex flex-column'>
                            {
                                Object.entries(importedPosts).map((platform) => {
                                    return (
                                        <span className='posts_icons' key={platform}>
                                            {
                                                platform[0] == "FACEBOOK"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-facebook"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                platform[0] == "INSTAGRAM"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-instagram"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                platform[0] == "PINTEREST"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-pinterest"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </span>
                                    )
                                })
                            }

                            {Object.keys(PostedData).map(platform => (
                                <span className='posts_icons' key={platform}>
                                    {
                                        platform == "FACEBOOK"
                                            ?
                                            <i class="fa-brands fa-facebook"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "INSTAGRAM"
                                            ?
                                            <i class="fa-brands fa-instagram"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "PINTEREST"
                                            ?
                                            <i class="fa-brands fa-pinterest"></i>
                                            :
                                            null
                                    }

                                    {PostedData[platform].length}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* task */}
                    <div style={{ height: "90%", }}>
                        {
                            genratedData.map((elem) => {
                                if ((elem.time).split("T")[0].split("-")[2] == date) {
                                    if (((elem.time).split("T")[0].split("-")[1]).replace(/^0+/, '') == month) {
                                        return (
                                            <span className='tasks' onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: elem } }) }}>
                                                <label>
                                                    {
                                                        elem.platform == "Instagram"
                                                            ?
                                                            <i class="fa-brands fa-instagram"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "Facebook"
                                                            ?
                                                            <i class="fa-brands fa-facebook"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "GMB"
                                                            ?
                                                            <i class="fa-brands fa-Google"></i>
                                                            :
                                                            null
                                                    }
                                                </label>
                                                <label>
                                                    1
                                                </label>
                                            </span>
                                        )
                                    }
                                }
                            })
                        }
                        <div className='action_task'>
                            <button className='tasks add_task' onClick={() => navigatie('/Dashboard/PublishPost', { state: { date: date, month: month } })}>
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>

                            <span className='tasks add_task'>
                                <i class='fa fa-edit'></i>
                            </span>
                        </div>
                    </div>
                </th>
            )
        }
    }
    else
        if (date < dataObject.format("D")) {
            return (
                <th key={date} className="previous-weeks-day">
                    <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                        {date}

                        <div className='mt-2 mx-2 d-flex flex-column'>
                            {
                                Object.entries(importedPosts).map((platform) => {
                                    return (
                                        <span className='posts_icons' key={platform}>
                                            {
                                                platform[0] == "FACEBOOK"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-facebook"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                platform[0] == "INSTAGRAM"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-instagram"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                platform[0] == "PINTEREST"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-pinterest"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </span>
                                    )
                                })
                            }

                            {Object.keys(PostedData).map(platform => (
                                <span className='posts_icons' key={platform}>
                                    {
                                        platform == "FACEBOOK"
                                            ?
                                            <i class="fa-brands fa-facebook"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "INSTAGRAM"
                                            ?
                                            <i class="fa-brands fa-instagram"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "PINTEREST"
                                            ?
                                            <i class="fa-brands fa-pinterest"></i>
                                            :
                                            null
                                    }

                                    {PostedData[platform].length}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* task */}
                    <div style={{ height: "90%" }}>
                        {
                            genratedData.map((elem) => {
                                if ((elem.time).split("T")[0].split("-")[2] == date) {
                                    if (((elem.time).split("T")[0].split("-")[1]).replace(/^0+/, '') == month) {
                                        return (
                                            <span className='tasks' onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: elem } }) }}>
                                                <label>
                                                    {
                                                        elem.platform == "Instagram"
                                                            ?
                                                            <i class="fa-brands fa-instagram"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "Facebook"
                                                            ?
                                                            <i class="fa-brands fa-facebook"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "GMB"
                                                            ?
                                                            <i class="fa-brands fa-Google"></i>
                                                            :
                                                            null
                                                    }
                                                </label>
                                                <label>
                                                    1
                                                </label>
                                            </span>
                                        )
                                    }
                                }
                            })
                        }

                        <div className='action_task'>
                            {
                                date < dataObject.format("D")
                                    ?
                                    null
                                    :
                                    <>
                                        <button className='tasks add_task' onClick={() => navigatie('/Dashboard/PublishPost', { state: { date: date, month: month } })}>
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                        <span className='tasks add_task'>
                                            <i class='fa fa-edit'></i>
                                        </span>
                                    </>
                            }

                        </div>
                    </div>
                </th>
            )
        }
        else {
            return (
                <th key={date} className="weeks-day">
                    <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                        {date}

                        <div className='mt-2 mx-2 d-flex flex-column'>
                            {
                                Object.entries(importedPosts).map((platform) => {
                                    return (
                                        <span className='posts_icons' key={platform}>
                                            {
                                                platform[0] == "FACEBOOK"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-facebook"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                platform[0] == "INSTAGRAM"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-instagram"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                platform[0] == "PINTEREST"
                                                    ?
                                                    <>
                                                        <i class="fa-brands fa-pinterest"></i>
                                                        {platform[1]}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </span>
                                    )
                                })
                            }

                            {Object.keys(PostedData).map(platform => (
                                <span className='posts_icons' key={platform}>
                                    {
                                        platform == "FACEBOOK"
                                            ?
                                            <i class="fa-brands fa-facebook"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "INSTAGRAM"
                                            ?
                                            <i class="fa-brands fa-instagram"></i>
                                            :
                                            null
                                    }

                                    {
                                        platform == "PINTEREST"
                                            ?
                                            <i class="fa-brands fa-pinterest"></i>
                                            :
                                            null
                                    }

                                    {PostedData[platform].length}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* task */}
                    <div style={{ height: "90%" }}>

                        {
                            genratedData.map((elem) => {
                                if ((elem.time).split("T")[0].split("-")[2] == date) {
                                    if (((elem.time).split("T")[0].split("-")[1]).replace(/^0+/, '') == month) {
                                        return (
                                            <span className='tasks' onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: elem } }) }}>
                                                <label>
                                                    {
                                                        elem.platform == "Instagram"
                                                            ?
                                                            <i class="fa-brands fa-instagram"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "Facebook"
                                                            ?
                                                            <i class="fa-brands fa-facebook"></i>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        elem.platform == "GMB"
                                                            ?
                                                            <i class="fa-brands fa-Google"></i>
                                                            :
                                                            null
                                                    }
                                                </label>
                                                <label>
                                                    1
                                                </label>
                                            </span>
                                        )
                                    }
                                }
                            })
                        }

                        <div className='action_task'>
                            {
                                date < dataObject.format("D")
                                    ?
                                    null
                                    :
                                    <>
                                        <button className='tasks add_task' onClick={() => navigatie('/Dashboard/PublishPost', { state: { date: date, month: month } })}>
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                        <span className='tasks add_task'>
                                            <i class='fa fa-edit'></i>
                                        </span>
                                    </>
                            }

                        </div>
                    </div>
                </th >
            )
        }
}
