import { message, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function Topics() {

    const [Editindex, setEditIndex] = useState();
    const [EditText, setEditText] = useState();
    const [Editcheck, setEditcheck] = useState(false);
    const [AddText,setAddText]=useState(null);

    const onChange = (checked, index) => {
        console.log(`switch to ${checked}`);
        if (Editindex == index) {
            setEditcheck(checked);
        }
    };

    const [topics, setTopics] = useState(null)

    const GetTopics = async () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.get(`${process.env.REACT_APP_BACKEND_API}/onboarding/get-onboarding/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setTopics(res.data.OnBoarding[0].content)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const EditTopic = async () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }
        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/update-content/${Editindex}`, {
            "text": EditText,
            "enabled": Editcheck
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setEditIndex(null);
                GetTopics()
                console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const DeleteTopic=async(index)=>{
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.delete(`${process.env.REACT_APP_BACKEND_API}/onboarding/delete-content/${index}`,{
            headers:{
                "Authorization":`Bearer ${token}`
            }
        })
        .then((res)=>{
            GetTopics()
        })
        .catch((err)=>{
            message.error("There is some error try again...")
        })
          
    }

    const AddTopic=async()=>{
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }
        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/update-content`, {
            "text": AddText,
            "enabled": false
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setEditIndex(null);
                GetTopics();
            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        GetTopics()
    }, [])

    return (
        <div>
            <div className='section_header'>
                <h4>
                    Topics
                </h4>
            </div>

            <div className='branding_conatiner'>
                <div className='brand_box' style={{ width: '70%', padding: 0 }}>
                    <div className='d-flex flex-column p-3'>
                        <label className='brand_head'>
                            Content Topics
                        </label>

                        <label className='sub_head'>
                            Marky uses your content topics to generate posts. Add or change your topics to see new content.
                        </label>
                    </div>

                    <div>
                        <table className='table topic_table'>
                            <thead>
                                <tr>
                                    <th>Enabled</th>
                                    <th>Text</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    topics != null
                                        ?
                                        topics.map((elem, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <Switch checked={Editindex==index?Editcheck:elem.enabled} defaultChecked={Editindex==index?Editcheck:elem.enabled} onChange={(e) => onChange(e, index)} />
                                                    </td>

                                                    <td className='d-flex justify-content-between align-items-center'>
                                                        {
                                                            Editindex == index
                                                                ?
                                                                <input className='profile_edit_input w-100' onChange={(e) => { setEditText(e.target.value) }} defaultValue={elem.text} type="text" />
                                                                :
                                                                elem.text
                                                        }

                                                        {
                                                            Editindex == index
                                                                ?
                                                                <button className='btn profile_save_btn mx-2' onClick={() => { EditTopic() }}>
                                                                    Save
                                                                </button>
                                                                :
                                                                <button className='btn' onClick={() => { setEditIndex(index) }}>
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                        }
                                                    </td>

                                                    <td>
                                                        <button className='btn' onClick={()=>DeleteTopic(index)}>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        null
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        <textarea className='new_topic_add_input' onChange={(e)=>{setAddText(e.target.value)}}></textarea>
                                        <div style={{ textAlign: "right" }}>
                                            <button className='topic_add_btn' onClick={()=>{AddTopic()}}>
                                                Add
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    )
}
