import React, { useState } from 'react'
import Steps from '../Steps'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CountryDropdown from '../Comman Components/CountryDropdown';
import { country } from '../Comman Components/Country';
import { TimeZone } from '../Comman Components/TimeZone';
import TimezoneDropdown from '../Comman Components/TimezoneDropdown';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Organization() {
    const [Mobile, setMobile] = useState();
    const navigate = useNavigate();
    const [isLoader, setLoader] = useState(false)
    const selector = useSelector((state) => { return state });

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;


    const [DataOrganization, setOrganizationData] = useState({
        Brandname: "",
        Brandaddress: "",
        Brandsize: "",
        Brandwebsite: "",
    })

    function handelOrganization(e) {
        e.preventDefault();
        const newdata = { ...DataOrganization }
        newdata[e.target.name] = e.target.value;
        setOrganizationData(newdata);
    }


    function OrganizationDataSend() {
        setLoader(true)
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/add-onboarding2`, {
            "brand_name": DataOrganization.Brandname,
            "brand_address": DataOrganization.Brandaddress,
            "brand_contact": Mobile,
            "brand_size": DataOrganization.Brandsize,
            "website": DataOrganization.Brandwebsite,
            "country": selector.Country[0],
            "time_zone": selector.Timezone[0]
        }, {
            headers: {
                'Authorization': `Barear ${token}`
            }
        })
            .then((res) => {
                // setDataView('Assets')
                UpdateStatus()
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }

    const CreateFirstGroup = () => {
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`, {
            groupName: DataOrganization.Brandname,
            connectedAccounts: []
        }, {
            headers: {
                'Authorization': `Barear ${token}`
            }
        })
            .then((res) => {
                setLoader(false)
                navigate("/AssetsData");
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }

    async function UpdateStatus() {
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/user/update-status`, {
            status: 'organization completed'
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                CreateFirstGroup()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className='bg-images'>
            <div className='onbording_box brand_onboarding_box'>
                <div className='brand_box'>
                    <div className='d-flex flex-column px-2'>
                        <label className='brand_head'>
                            Your Brand
                        </label>

                        <label className='sub_head'>
                            Choose how your business presents itself.
                        </label>
                    </div>

                    <hr />

                    <div>
                        <div className='d-flex flex-column'>
                            <label className='input_label'>
                                Brand Name
                            </label>

                            <input type="text" onChange={(e) => handelOrganization(e)} name='Brandname' className='form_input' />
                        </div>

                        <div className='d-flex flex-column'>
                            <label className='input_label'>
                                Brand Size
                            </label>

                            <input type="number"  onChange={(e) => handelOrganization(e)} name='Brandsize' className='form_input' />
                        </div>

                        <div className='d-flex flex-column'>
                            <label className='input_label'>
                                Phone Number
                            </label>

                            <PhoneInput
                                defaultCountry="IN"
                                value={Mobile}
                                onChange={setMobile}
                                limitMaxLength={true}
                                className='form_input_phone'
                            />
                        </div>

                        <div className='d-flex flex-column'>
                            <label className='input_label'>
                                Address
                            </label>

                            <input type="text" onChange={(e) => handelOrganization(e)} name="Brandaddress" className='form_input' />
                        </div>

                        <div className='d-flex flex-column'>
                            <label className='input_label'>
                                Website
                            </label>

                            <input type="text" onChange={(e) => handelOrganization(e)} name="Brandwebsite" className='form_input' />
                        </div>

                        <div className='d-flex flex-column'>
                            <label className='input_label'>
                                Country
                            </label>

                            <CountryDropdown
                                countryList={country}
                            />
                        </div>

                        <div className='d-flex flex-column'>
                            <label className='input_label'>
                                Time Zone
                            </label>

                            <TimezoneDropdown
                                Timezonelist={TimeZone}
                            />

                        </div>

                        <div className='d-flex justify-content-between mt-3'>
                            <button className='back_btn' onClick={() => { navigate('/Onboarding') }}>
                                Back
                            </button>

                            <button className='next_btn' onClick={() => OrganizationDataSend()}>
                                {
                                    isLoader
                                        ?
                                        <Spin indicator={antIcon} />
                                        :
                                        "Next"
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
